import apiStore from './apiStore'
import sessionIdParm from './sessionIdParm'

var BARCODE_DELIMITER = ';:;'

export const apiGetProduct = (account, session, id, showInactive, scanned, doneCallback, failCallback, timeout) => {
	var hostName = account.hostName
	var idParm

	if (!Array.isArray(id)) {
		idParm = id
	} else {
		for (var i = 0; i < id.length; i++) {
			if (i === 0) {
				idParm = encodeURIComponent(id[i])
			} else {
				idParm += BARCODE_DELIMITER + encodeURIComponent(id[i])
			}
		}
	}

	var inventoryStoreNumber = account.inventoryStoreNumber

	const url =
		hostName +
		'/apiGetProduct' +
		'?id=' +
		idParm +
		'&shwInactive=' +
		(showInactive || 'true') +
		'&s=' +
		(scanned || '0') +
		'&isn=' +
		inventoryStoreNumber +
		'&apiToken=' +
		session.apiToken +
		sessionIdParm(session)

	apiStore({
		url: url,
		method: 'get',
		timeout: timeout || 10000,
	})
		.then((response) => {
			if (response.data.Error === 'Too many items for grid.') {
				failCallback('Too many items for grid.')
			}
			if (response.data.BARCODE === 0) {
				failCallback('Could not find any items.')
			} else {
				doneCallback(response.data)
			}
		})
		.catch((error) => {
			failCallback('Unable to contact the server.')
		})
}

export default apiGetProduct
