import { updateObject } from '../utility'
import { storesSetFromLogin1, storesSetFromLogin2, storesSetFromAccountDialog } from './storesReducer'
import Session from '../../classes/Session'
import makeUuid from '../../utils/makeUuid'
import { hex_hmac_md5 } from '../../utils/md5'
import { getSelectedAccount } from '../../components/Tabs/SettingsTab/Accounts/getSelectedAccount'
import { showToast, TOAST_SEVERITY } from '../../components/Toast/toastReducer'
import { TABBAR_TAB, setTab, disableTab, enableTab } from '../../components/Tabs/TabBar/tabBarReducer'
import { PATHS } from './pathReducer'
import isNonBlankString from '../../utils/isNonBlankString'
import { dashboardLoad } from '../../components/Tabs/DashboardTab/dashboardReducer'
import {
    // loadFromLocalStorage, 
    saveToLocalStorage
} from '../../utils/localStorage'
import { osVersion, osName, mobileModel, mobileVendor, browserName } from 'react-device-detect';
import {
    deleteAccount,
    editAccount 
} from './accountsReducer'
// import { isNullOrUndefined } from 'util'
import log from '../../utils/log'
import apiStore from '../../utils/apiStore'
import sessionIdParm from '../../utils/sessionIdParm'
import { setPaymentStores } from './paymentsReducer'
import { logActivity } from './accountsReducer'
import { LOG_TYPES } from '../../logging/logTypes'

/*****************************************************************************/

export const currentVersion = 1.400  // ***** rev this when the server api is out of sync. Be sure to adjust setLowerVersion below!!!!
/*****************************************************************************/
// Module data

const LOGIN_SELECTING_ACCOUNT = '/login/LOGIN_SELECTING_ACCOUNT'
const LOGIN_START = '/login/LOGIN_START'
const LOGIN_SUCCESS = '/login/LOGIN_SUCCESS'
const LOGIN_FAILURE = '/login/LOGIN_FAILURE'
const LOGIN_NO_OP = '/login/LOGIN_NO_OP'
const LOGIN_SERVER_ACCESSED = '/login/LOGIN_SERVER_ACCESSED'
const LOGIN_REFRESH_MINUTE = '/login/LOGIN_REFRESH_MINUTE'
const LOGIN_DISCONNECT = '/login/LOGIN_DISCONNECT'
const LOGIN_ANOTHER_ACCOUNT = '/login/LOGIN_ANOTHER_ACCOUNT'
const LOGIN_REGRESS_VERSION = '/login/LOGIN_REGRESS_VERSION'

const CREDENTIAL_FAILURE = '/login/CREDENTIAL_FAILURE'
const CLEAR_LOGIN_ATTEMPTS = '/login/CLEAR_LOGIN_ATTEMPTS'
const SET_DEVICE_ID = '/login/SET_DEVICE_ID'
const SAVE_URL = '/login/SAVE_URL'

const UPDATE_ERROR_NOTIFICATION = '/login/UPDATE_ERROR_NOTIFICATION'
const SET_LOGIN_FORM_FIELDS = '/login/SET_LOGIN_FORM_FIELDS'

export const LOGIN_MODE = Object.freeze({
    disconnected: 'Disconnected',
    authenticating: 'Authenticating',
    reauthenticating: 'Reauthenticating',
    authenticated: 'Authenticated',
    error: 'Error',
    credentialError: 'credentialError'
})

export const LOGIN_SERVER_REFRESH_MINUTES = 15
export const LOGIN_SERVER_REFRESH_INTERVAL = 60000

const initialState = {
    mode: LOGIN_MODE.disconnected,
    selectingAccount: false,
    idleMinuteCounter: 0,
    lowerVersion: undefined,
    error: null,
    session: new Session(),
    isRetailUser: true,
    retailUserEnabled: false,
    cartEnabled: true,
    paymentsEnabled: true,
    deviceID: null,

    failedLoginAttempts: 0,
    url: "",

    errorNotification: {
        show: false,
        header: "",
        body: ""
    },

    loginFormFields: {
        accountName: "",
        hostName: "",
        userName: "",
        password: ""
    }
}

let deviceID = null


/*****************************************************************************/
// Action Creators

export const loginSelectingAccount = selectingAccount => ({
    type: LOGIN_SELECTING_ACCOUNT,
    selectingAccount: selectingAccount
})

export const loginStart = isReauthenticating => ({
    type: LOGIN_START,
    mode: !isReauthenticating ? LOGIN_MODE.authenticating : LOGIN_MODE.reauthenticating,
})

export const loginFailure = error => ({
    type: LOGIN_FAILURE,
    error: error
})

export const credentialFailure = error => ({
    type: CREDENTIAL_FAILURE,
    error: error
})

export const setDeviceID = deviceID => ({
    type: SET_DEVICE_ID,
    deviceID: deviceID
})

// Find a better way to ignore requests to login when it's already underway
export const loginNoOp = () => ({ type: LOGIN_NO_OP })

export const loginServerAccessed = () => ({ type: LOGIN_SERVER_ACCESSED })

export const loginRefreshMinute = () => ({ type: LOGIN_REFRESH_MINUTE })

export const loginAnotherAccount = () => ({ type: LOGIN_ANOTHER_ACCOUNT })

export const login = (pathDirector, editedAccount, reasonCodeAddition, limitToLogin1) => { //editedAccount is passed only by the EditAccounts form, when a temporary login needs to occur for the Options dialog
    log('loginReducer/login')
    return (dispatch, getState) => {

        //Get device Id
        deviceID = getState().loginReducer.deviceID
        
        if (deviceID === null) {
            deviceID = makeUuid(36)
            saveToLocalStorage('deviceID', deviceID)
            dispatch(setDeviceID(deviceID))
        }

        //Create a new state with the deviceID
        const newState = { ...getState().loginReducer, loginId: deviceID, deviceID: deviceID }

        //Get selected account
        const selectedAccount = editedAccount || getSelectedAccount(getState().accountsReducer)

        log("Login")
        log("Login ID: ", newState.loginId)
        log("Selected Account:", selectedAccount)
        log("Working Version: " + workingVersion(newState).toFixed(3))

        //Stop trying to log in.
        if (newState.failedLoginAttempts >= 1) {
            dispatch(credentialFailure("Unable to authenticate with server."))
            dispatch(logActivity(LOG_TYPES.login, "Unable to authenticate with server.", false))
            return
        }

        //See if the selected account is a guest.
        if (selectedAccount) {
            if (selectedAccount.isGuest) {
                dispatch(disableTabs())
                dispatch(showToast('Signed in as a guest account.', TOAST_SEVERITY.warning, 3000, 'top'))
                dispatch(setTab(TABBAR_TAB.settings, pathDirector, PATHS.accounts))
                dispatch(logActivity(LOG_TYPES.login, "Signed in as guest.", true))
                return
            }
            login1(dispatch, selectedAccount, newState, reasonCodeAddition, limitToLogin1, pathDirector)
        }
    }
}

export const disableTabs = () => {
    return (dispatch, getState) => {
        dispatch(disableTab(TABBAR_TAB.dashboard))
        dispatch(disableTab(TABBAR_TAB.items))
        dispatch(disableTab(TABBAR_TAB.cart))
    }
}

export const enableTabs = () => {
    return (dispatch, getState) => {
        dispatch(enableTab(TABBAR_TAB.dashboard))
        dispatch(enableTab(TABBAR_TAB.items))
        dispatch(enableTab(TABBAR_TAB.cart))
    }
}

export const loginDisconnect = resetLowerVersion => ({
    type: LOGIN_DISCONNECT,
    resetLowerVersion: resetLowerVersion
})

export const connectToServer = (pathDirector_omitOnReauthentication, resetLowerVersion = true) => {
    log('loginReducer/connectToServer')
    return (dispatch, getState) => {
        dispatch(loginDisconnect(resetLowerVersion))
        dispatch(clearLoginAttempts())
        dispatch(login(pathDirector_omitOnReauthentication))
    }
}

export const clearLoginAttempts = () => ({ type: CLEAR_LOGIN_ATTEMPTS })

/*****************************************************************************/
// Reducer

export default function loginReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN_SELECTING_ACCOUNT: return updateOn_LOGIN_SELECTING_ACCOUNT(state, action)
        case LOGIN_START: return updateOn_LOGIN_START(state, action)
        case LOGIN_SUCCESS: return updateOn_LOGIN_SUCCESS(state, action)
        case LOGIN_FAILURE: return updateOn_LOGIN_FAILURE(state, action)
        case LOGIN_NO_OP: return state
        case LOGIN_SERVER_ACCESSED: return updateOn_LOGIN_SERVER_ACCESSED(state, action)
        case LOGIN_REFRESH_MINUTE: return updateOn_LOGIN_REFRESH_MINUTE(state, action)
        case LOGIN_DISCONNECT: return updateOn_LOGIN_DISCONNECT(state, action)
        case CREDENTIAL_FAILURE: return updateOn_CREDENTIAL_FAILURE(state, action)
        case CLEAR_LOGIN_ATTEMPTS: return updateOn_CLEAR_LOGIN_ATTEMPTS(state, action)
        case LOGIN_ANOTHER_ACCOUNT: return updateOn_LOGIN_ANOTHER_ACCOUNT(state, action)
        case LOGIN_REGRESS_VERSION: return updateOn_LOGIN_REGRESS_VERSION(state, action)
        case SET_DEVICE_ID: return updateOn_SET_DEVICE_ID(state, action)
        case SAVE_URL: return updateOn_SAVE_URL(state, action)
        case UPDATE_ERROR_NOTIFICATION: return updateOn_UPDATE_ERROR_NOTIFICATION(state, action)
        case SET_LOGIN_FORM_FIELDS: return updateOn_SET_LOGIN_FORM_FIELDS(state, action)
        default: return state
    }
}

const updateOn_LOGIN_SELECTING_ACCOUNT = (state, action) => (
    updateObject(state, { selectingAccount: action.selectingAccount })
)

const updateOn_LOGIN_START = (state, action) => (
    updateObject(state, { mode: action.mode })
)

const updateOn_LOGIN_SUCCESS = (state, action) => (
    updateObject(state, {
        mode: LOGIN_MODE.authenticated,
        retailUserEnabled: action.state.retailUserEnabled,
        isRetailUser: action.state.isRetailUser,
        error: null,
        session: {
            ...state.session,
            apiKey: action.state.session.apiToken,
            sessionId: action.state.session.sessionId,
            deviceId: action.state.session.deviceId,
            deviceName: action.state.session.deviceName,
            deviceModel: action.state.session.deviceModel,
            devicePlatform: action.state.session.devicePlatform,
            deviceVersion: action.state.session.deviceVersion
        },
        failedLoginAttempts: 0
    })
)

const updateOn_LOGIN_FAILURE = (state, action) => (
    updateObject(state, {
        mode: LOGIN_MODE.disconnected,
        error: action.error,
        session: new Session(),
        failedLoginAttempts: state.failedLoginAttempts += 1
    })
)

const updateOn_CREDENTIAL_FAILURE = (state, action) => (
    updateObject(state, {
        mode: LOGIN_MODE.credentialError,
        error: action.error,
        session: new Session(),
    })
)

const updateOn_LOGIN_SERVER_ACCESSED = (state, action) => (
    updateObject(state, { idleMinuteCounter: 0 })
)

const updateOn_LOGIN_REFRESH_MINUTE = (state, action) => (
    updateObject(state, { idleMinuteCounter: state.idleMinuteCounter + 1 })
)

const updateOn_LOGIN_DISCONNECT = (state, action) => {
    log("%c updateOn_LOGIN_DISCONNECT", 'color: red; background: white;')
    return updateObject(state, {
        mode: LOGIN_MODE.disconnected,
        session: new Session(),
        lowerVersion: (!action.resetLowerVersion) ? state.lowerVersion : undefined
    })
}

const updateOn_CLEAR_LOGIN_ATTEMPTS = (state, action) => (
    updateObject(state, { failedLoginAttempts: 0 })
)

const updateOn_LOGIN_ANOTHER_ACCOUNT = (state, action) => {
    log("%c updateOn_LOGIN_ANOTHER_ACCOUNT ", 'color: red; background: white;')
    return updateObject(state, {
        lowerVersion: undefined,
        session: new Session()
    })
}

const updateOn_LOGIN_REGRESS_VERSION = (state, action) => {
    log("%c updateOn_LOGIN_REGRESSION_VERSION ", 'color: red; background: white;')
    return updateObject(state, { lowerVersion: action.payload.lowerVersion })
}

const updateOn_SET_DEVICE_ID = (state, action) => (
    updateObject(state, { deviceID: action.deviceID })
)

const updateOn_SAVE_URL = (state, action) => (
    updateObject(state, { url: action.payload.url })
)

const updateOn_UPDATE_ERROR_NOTIFICATION = (state, action) => (
    updateObject(state, { errorNotification: action.payload })
)

const updateOn_SET_LOGIN_FORM_FIELDS = (state, action) => (
    updateObject(state, { loginFormFields: action.payload })

)

/*****************************************************************************/
// Module Functions

const setLowerVersion = (dispatch, newState) => {

    const regressVersion = (lowerVersion, cartEnabled, paymentsEnabled) => {
        log('regressVersion', lowerVersion, cartEnabled, paymentsEnabled)
        return {
            type: LOGIN_REGRESS_VERSION,
            payload: {
                lowerVersion: lowerVersion,
                cartEnabled: cartEnabled,
                paymentsEnabled: paymentsEnabled,
            }
        }
    }

    // This will not be updated these varables directly.
    switch (workingVersion(newState)) {
        case 1.400: // Payments
            dispatch(regressVersion(1.300, true, false))
            return true

        case 1.300: // Carts
            dispatch(regressVersion(1.200, false, false))
            return true

        case 1.200: // Multi-Store Inventory
            dispatch(regressVersion(1.100, false, false))
            return true

        default:
            return false
    }
};

const workingVersion = state => {
    // log('workingVersion', state.lowerVersion, currentVersion, state.lowerVersion || currentVersion)
    return state.lowerVersion || currentVersion
}

export const isLoginModeDisconnected = mode => (
    [
        LOGIN_MODE.disconnected,
        LOGIN_MODE.error,
        LOGIN_MODE.credentialError
    ].includes(mode)
)

export const isLoginModeAuthenticating = mode => (
    [LOGIN_MODE.authenticating, LOGIN_MODE.reauthenticating].includes(mode)
)

export const isLoginModeReauthenticating = mode => (
    mode === LOGIN_MODE.reauthenticating
)

export const isLoginModeAuthenticated = mode => (
    mode === LOGIN_MODE.authenticated
)

/*****************************************************************************/
// login1 Functions

// login1 does the first call to the WebServer apiLogin. It retrieves version information, the store list, etc.
// On the happy path, it calls login2 to perform the actual login, which retrieves the token from the server.

const login1 = (dispatch, selectedAccount, newState, reasonCodeAddition, limitToLogin1, pathDirector) => {
    log("Login1")
    log("Login ID: ", newState.loginId)
    log("Selected Account:", selectedAccount)
    log("Working Version: " + workingVersion(newState).toFixed(3))

    if (!newState.lowerVersion && isLoginModeAuthenticating(newState.mode)) {
        log("LoginNoOp()")
        dispatch(loginNoOp())
    } else {
        const isReauthenticating = !pathDirector
        dispatch(loginStart(isReauthenticating))

        const url = selectedAccount.hostName
            + '/apiLogin'
            + '?d=' + newState.loginId
            + '&dm=' + (mobileModel || mobileVendor) // '&dm=' + 'PWA' //Cordova device.model or DevExpress.devices.current().platform
            + '&dp=' + (osName || browserName) // '&dp=' + 'PWA' //DevExpress.devices.current().platform
            + '&dv=' + osVersion // '&dv=' + '0.x' //Cordova device.version
            + '&apiVersion=' + workingVersion(newState).toFixed(3)
            + sessionIdParm(newState.session)
            + (reasonCodeAddition ? '&ra=' + reasonCodeAddition : '')
        dispatch(saveUrl(url))

        apiStore({
            url: url,
            method: 'get',
            timeout: 6000
        })
            .then(response => {
                log('login1/then:', response)
                if (newState.loginId !== deviceID) {
                    log('%c Aborted login1 ' + newState.loginId, 'color: red;')
                    return
                }
                log('%c Proceeding login1 ' + newState.loginId, 'color: green;')
                dispatch(enableTabs())
                takeLogin1Response(dispatch, selectedAccount, newState, reasonCodeAddition, response, limitToLogin1, pathDirector)
            })
            .catch(error => {
                log('login1/catch:', error)
                log('%c Failed login1 ' + newState.loginId + '(' + error + ')', 'color: red;')
                dispatch(disableTabs())
                takeLogin1Error(dispatch, selectedAccount, newState, error, limitToLogin1, pathDirector)
            })
    }
}

const takeLogin1Response = (dispatch, selectedAccount, newState, reasonCodeAddition, response, limitToLogin1, pathDirector) => {

    log("takeLogin1Response")
    log("Login ID: ", newState.loginId)
    log("Selected Account:", selectedAccount)
    log("Working Version: " + workingVersion(newState).toFixed(3))
    dispatch(loginServerAccessed())

    if (typeof response.data === 'string') {
        log("%ctakeLoginResponse Failed. A string was returned.", "color: red;")
        log('takeLogin1Response/A', response)
        dispatchLoginFailure(dispatch, {
            message: 'Unable to connect, please check that the Web Server Domain or IP in the account settings is correct.',
            title: 'Check Server Settings',
            error: response.data
        })
    } else {
        log("%ctakeLoginResponse. The response is not a string.", "color: lime;")
        log('takeLogin1Response/B', response)
        newState.session.apiKey = response.data.S
        if (response.data.S === "" || isNonBlankString(response.data.APIVERINFO)) {
            newState.session.sessionId = "";
            log('APIVERINFO=' + response.data.APIVERINFO);
            if (isNonBlankString(response.data.APIVERINFO)) {
                if (setLowerVersion(dispatch, newState)) {
                    log("%cLogin1Response2 working verison " + workingVersion(newState).toFixed(3), "color: orange;")
                    log("%ctakeLogin1Response DOWNGRADING WORKING VERSION. START RELOGIN.", "color: red")
                    const dontResetLowerVersion = false
                    dispatch(connectToServer(pathDirector, dontResetLowerVersion))
                    return;
                } else {
                    log("%ctakeLogin1Response CANNOT DOWNGRADE WORKING VERSION FURTHER", "color: red")
                    dispatch(showToast('You must upgrade The Uniform Solution+ server to use The Uniform Solution mobile app.', TOAST_SEVERITY.error))
                    dispatch(disableTabs())
                    dispatch(setTab(TABBAR_TAB.settings, pathDirector, PATHS.accounts))
                    return
                }
            } else {
                log("%ctakeLogin1Response CANNOT GET CURRENT API VERSION", "color: red")
                dispatch(showToast('Unable to get a session key from the server at this time, please contact tech support.', TOAST_SEVERITY.error))
                dispatch(disableTabs())
                dispatch(setTab(TABBAR_TAB.settings, pathDirector, PATHS.accounts))
                return
            }

        } else {
            log("%ctakeLogin1Response Got a valid api version!", "color: lime;")
            newState.session.sessionId = response.data.SESSIONID;

            //Check to see if all stores is present
            var stores = []
            if (response.data.ALL_STORES !== undefined) {
                stores = response.data.ALL_STORES
            }
            else {
                stores = response.data.STORES
            }
            if (limitToLogin1) {
                dispatch(storesSetFromAccountDialog(stores))
                return
            }
            //Populates stores list in storesReducer.
            dispatch(storesSetFromLogin1(response.data))
            login2(dispatch, selectedAccount, newState, reasonCodeAddition, pathDirector)
        }
    }
}

const takeLogin1Error = (dispatch, selectedAccount, newState, error, limitToLogin1, pathDirector) => {

    log("takeLogin1Error", error)
    log("Login ID: ", newState.loginId)
    log("Selected Account:", selectedAccount)
    log("Working Version: " + workingVersion(newState).toFixed(3))

    dispatch(logActivity(LOG_TYPES.login, 'Could not login. LoginError1.', false))

    if (selectedAccount.isPending === true) {
        log("Deleting the pending account. [takeLogin1Error]")
        dispatch(deletePendingAccount(selectedAccount))
    }

    if (limitToLogin1) {
        dispatch(showToast('Unable to load store list', TOAST_SEVERITY.error))
        dispatchCredentialFailure(dispatch, {
            message: 'Unable to validate account credentials.',
            title: 'Account Credential Error',
            error: error
        })
        return
    }
    dispatch(setTab(TABBAR_TAB.settings, pathDirector, PATHS.accounts))
    dispatchLoginFailure(dispatch, {
        message: 'Unable to contact the WebStore. Please make sure your WebStore URL is correct, the WebStore is running, and it has SSL enabled.',
        title: 'Login Error',
        error: error
    })
}

/*****************************************************************************/
// login2 Functions
// Once the stage has been set by login1, login2 fetches the authentication token

const login2 = (dispatch, selectedAccount, newState, reasonCodeAddition, pathDirector) => {

    log("login2")
    log("Login ID: ", newState.loginId)
    log("Selected Account:", selectedAccount)
    log("Working Version: " + workingVersion(newState).toFixed(3))

    const url = selectedAccount.hostName
        + '/apiLogin'
        + "?info=" + selectedAccount.userName
        + "&isn=" + selectedAccount.inventoryStoreNumber
        + "&n=" + hex_hmac_md5(newState.session.apiKey, selectedAccount.password.trim().toUpperCase())
        + sessionIdParm(newState.session)
        + (reasonCodeAddition ? '&ra=' + reasonCodeAddition : '')

    apiStore({
        url: url,
        method: 'get'
    })
        .then((response) => {
            if (newState.loginId !== deviceID) {
                log('%c Aborted login2 ' + newState.loginId, 'color: red;')
                return
            }
            log('%c Proceeding login2 ' + newState.loginId, 'color: green;')
            takeLogin2Response(dispatch, selectedAccount, newState, response, pathDirector)
        })
        .catch((error) => {
            log('%c Failed login1 ' + newState.loginId + '(' + error + ')', 'color: red;')
            dispatch(disableTabs())
            takeLogin2Error(dispatch, error, pathDirector)
        })
}


const takeLogin2Response = (dispatch, selectedAccount, newState, response, pathDirector) => {

    log("takeLogin2Response")
    log("Login ID: ", newState.loginId)
    log("Selected Account:", selectedAccount)
    log("Working Version: " + workingVersion(newState).toFixed(3))

    if (response.data.TOKEN === "") {
        log('%c Failed takeLogin2Response ' + newState.loginId, 'color: red;')
        log(response.data.FAILREASON)

        //if the account is a pending account, delete it.
        if (selectedAccount.isPending === true) {
            log("Deleting the pending account. [takeLogin2Error]")
            dispatch(deletePendingAccount(selectedAccount))
        }

        dispatch(setTab(TABBAR_TAB.settings, pathDirector, PATHS.accounts))
        dispatch(disableTabs())
        dispatchCredentialFailure(dispatch, {
            message: 'Unable to validate account credentials.',
            title: 'Account Credential Error',
            error: 'Credential Error'
        })

    } else {

        log('%c Proceeding takeLogin2Response ' + newState.loginId, 'color: green;')
        log(response.data)
        dispatch(storesSetFromLogin2(response.data, selectedAccount))
        const enableToast = Boolean(pathDirector)
        //Change the login mode to authenticated
        dispatchLoginSuccess(dispatch, selectedAccount, newState, response.data, enableToast)
        dispatch(enableTabs())
    }
}

const takeLogin2Error = (dispatch, error, pathDirector) => {
    log("takeLogin2Error")
    log('Catch Login State 2', error)
    dispatch(logActivity(LOG_TYPES.login, 'Could not login. LoginError2.', false))


    dispatch(disableTabs())
    dispatch(setTab(TABBAR_TAB.settings, pathDirector, PATHS.accounts))
    dispatchCredentialFailure(dispatch, {
        message: 'Unable to validate account credentials.',
        title: 'Account Credential Error',
        error: 'Credential Error'
    })
}

const dispatchLoginSuccess = (dispatch, selectedAccount, newState, data, enableToast = true) => {
    
    dispatch(logActivity(LOG_TYPES.login, 'Successfully connected to the server.', true))
    dispatch(loginServerAccessed())
    newState.session.apiToken = data.TOKEN
    newState.session.sessionId = data.SESSIONID

    newState.retailUserEnabled = (data.ISRETAILUSER !== undefined)
    newState.isRetailUser = !newState.retailUserEnabled ? false : (data.ISRETAILUSER === 1)
    log('ISRETAILUSER', typeof data.ISRETAILUSER, data.ISRETAILUSER, newState.retailUserEnabled, (data.ISRETAILUSER === 1), newState.isRetailUser)

    newState.session.access34 = (data.ACCESS34 > 0)
    newState.session.access36 = (data.ACCESS36 > 0)
    newState.session.access37 = (data.ACCESS37 > 0)
    newState.session.access79 = (data.ACCESS79 > 0)
    newState.session.sessionId = data.SESSIONID

    //Set account to be non-pending. It connected to the server!
    var newAccount = {...selectedAccount}
    if(newAccount.isPending === true){
        newAccount.isPending = false
        dispatch(editAccount(newAccount, false))
    }

    //TODO: The DashbaordTab needs to render based upon this type of logic
    // if (newState.isRetailUser) {
    //     DdsDash.app.navigation[0].option('title', "Store");
    //     DdsDash.app.navigation[0].option('icon', "home");
    //     DdsDash.app.navigation[2].option('visible', false);
    // } else {
    //     DdsDash.app.navigation[0].option('title', "Dashboard");
    //     DdsDash.app.navigation[0].option('icon', "chart");
    //     DdsDash.app.navigation[2].option('visible', DdsDash.cartEnabled);
    // }

    //TODO: DevExpress.ui.notify("You are now logged into the server.", "success", 1000);

    //Save type of user to local storage for loading appbar.
    saveToLocalStorage('isRetailUser', data.ISRETAILUSER)

    if (enableToast) dispatch(showToast('Login Success!', TOAST_SEVERITY.success))
    dispatch({ type: LOGIN_SUCCESS, state: newState })

    // If mode was already Authenticated, then it's a reauthentication.
    // For all other prior modes, load the dashboard.
    if (newState.mode !== LOGIN_MODE.authenticated) dispatch(dashboardLoad(true))
}

const dispatchLoginFailure = (dispatch, error) => {
    log('dispatchLoginFalure', error)
    // dispatch(showToast(error.message || 'Login Error!', TOAST_SEVERITY.error, 6000))
    dispatch(loginFailure(error))
}

const dispatchCredentialFailure = (dispatch, error) => {
    log('dispatchCredentialFailure', error)
    // dispatch(showToast(error.message || 'Credential Error!', TOAST_SEVERITY.error, 6000))
    dispatch(credentialFailure(error))
}

const saveUrl = (url) => {
    return (dispatch, getState) => {
        dispatch({
            type: SAVE_URL,
            payload: { url: url }
        })
    }
}

export const hideErrorNotification = () => {
    return (dispatch, getState) => {
        dispatch({
            type: UPDATE_ERROR_NOTIFICATION,
            payload: {
                show: false,
                header: "",
                body: ""
            }
        })
    }
}

// const showLoginFailNotification = (reasonCode) => {
//     return (dispatch, getState) => {

//         function showError(header, body) {
//             dispatch({
//                 type: UPDATE_ERROR_NOTIFICATION,
//                 payload: {
//                     show: true,
//                     header: header,
//                     body: body
//                 }
//             })
//         }

//         switch (reasonCode) {
//             case 1:
//                 showError(
//                     "Login not accepted",
//                     "Please check your user name and password and try again."
//                 )
//                 break
//             case 2:
//                 showError(
//                     "Device not Authorized",
//                     "Your mobile device is not authorized for use on this account. Please contact your administrator for more information."
//                 )
//                 break
//             case 3:
//                 showError(
//                     "Device Pending Authorization",
//                     "Your mobile device is waiting for authorization to use this account. Please contact your administrator to complete the authorization."
//                 )
//                 break
//             case 4:
//                 showError(
//                     "Device not Authorized",
//                     "An unexpected error occured on the server that prevent your mobile device from using this account. Please contact your administator about this problem."
//                 )
//                 break
//             case 5:
//                 showError(
//                     "No Active WebStore Configuration",
//                     "The server must have an active WebStore Configuration to enable mobile access to your system. WebStore Configurations are set up by selecting Setup > WebStore. Please contact your system administrator or technical support to resolve this problem if needed."
//                 )
//                 break
//             default:
//                 showError(
//                     "Unknown Failure Reason",
//                     "An unknown login failure reason was returned."
//                 )
//                 break
//         }
//     }
// }

const deletePendingAccount = (account) => {
    return (dispatch, getState) => {
        log("%cdelete pending accounts", "color: red; font-size: 18px;")
        dispatch(deleteAccount(account.key))
    }
}

//Helper function for printing in loginReducer
// const print = (text) => {
//     log("%c" + text, "color: gold; font-size: 20px")
// }

export const openLoginForm = (pathDirector) => {
    return (dispatch, getState) => {
        dispatch(setTab(TABBAR_TAB.settings, pathDirector, PATHS.accounts))
    }
}

export const setLoginFormFields = (loginFormFields) => {
    return (dispatch, getState) => {
        log("SetLoginFormFields")
        dispatch({
            type: SET_LOGIN_FORM_FIELDS,
            payload: { loginFormFields: loginFormFields }
        })
    }
}