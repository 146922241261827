import React from 'react';
import { CircularProgress} from "@mui/material"

const DialogLoading = () => {
    return (
        <div style={{display: "flex", height: "100%", width: "100%", justifyContent: "center", alignItems: "center", minHeight: "20vh", minWidth: "20vw"}}>
            <CircularProgress/>
        </div> 
    );
};

export default DialogLoading;