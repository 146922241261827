import axios from 'axios'
import log from './log'
import https from "https"

export const apiStore = config => {
    const httpsAgent = new https.Agent({
        rejectUnauthorized: false
    })

    const _config = {
        ...config,
        httpsAgent: httpsAgent
    }

    const securePrefix = 'https://'
    const nonSecurePrefix = 'http://'

    if (_config.url && !_config.url.includes(securePrefix)) {
        _config.url = securePrefix + _config.url.replace(nonSecurePrefix, '')
    }

    log('apiStore', _config)
    return axios(_config)
}

export default apiStore