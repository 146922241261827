import apiStore from './apiStore'
import sessionIdParm from './sessionIdParm'

export const apiInventorySearch = (account, session, searchOptions, doneCallback, failCallback, limitToVendor, maxResults, timeout) => {
    var returnMax = ''
    if (!maxResults) {
        maxResults = 250
        returnMax = 'false'
    } else {
        returnMax = 'true'
    }

    const url = account.hostName
        + "/apiInventorySearch?search=" + encodeURIComponent(searchOptions.searchValue)
        + "&searchCond=" + String(searchOptions.condition)
        + "&shwInactive=" + (searchOptions.inactiveFilter || 'false')
        + "&searchDesc=" + (searchOptions.lookIn === 'styleOrID' ? '0' : '1')
        + "&maxResults=" + maxResults
        + "&limitToVendor=" + (limitToVendor || '')
        + "&returnMax=" + returnMax
        + "&apiToken=" + session.apiToken + sessionIdParm(session)

    apiStore({
        url: url,
        method: 'get',
        timeout: timeout || 10000
    })
        .then(response => {
            if (response.data.NUMBEROFITEMS === 0) {
                failCallback('Could not find any items.')
            } else {
                doneCallback(response.data)
            }
        })
        .catch(error => {
            failCallback("Unable to contact the server.")
        })
}

export default apiInventorySearch
