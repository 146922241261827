import React from 'react'
import PropTypes from 'prop-types';
import clsx from 'clsx';
import DialogButton from "./DialogButton"


const CancelDialogButton = (props) => {
    const { classes, children, className, ...other } = props

    return(
        <DialogButton 
            color={props.color || "inherit"} 
            variant={props.variant || "contained"} 
            className={className}
            sx={{
                fontSize: "14px", 
                fontFamily: "Open Sans",
                backgroundColor: "#9e9e9e",
                color: "white",
                minWidth: "90px",
                '&:hover': {
                    backgroundColor: "#757575"
                },
                "&:disabled": {
                    color: "white",
                    backgroundColor: "#e0e0e0"
                }
            }}
            {...other}
        >
            {children || "CANCEL"}
        </DialogButton>
    )
}

DialogButton.propTypes = {
    children: PropTypes.node,
}

export default CancelDialogButton;