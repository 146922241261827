import { updateObject } from '../utility'

/*****************************************************************************/
// Module data

const TAB_NAME = Object.freeze({
    dashboard: 'dashboard',
    items: 'items',
    cart: 'cart',
    payments: 'payments',
    settings: 'settings',
})

// export const HASH = Object.freeze({
//     enterItem: POUND + 'enteritem',
// })

const makePath = (...segments) => {
    let path = ''
    for (let x = 0; x < segments.length; x++) {
        path += '/' + segments[x]
    }
    return path
}

export const PATHS = Object.freeze({
    goBack: '<',

    dashboardTab: makePath(TAB_NAME.dashboard),

    itemsTab: makePath(TAB_NAME.items),
    scanItem: makePath(TAB_NAME.items, 'scanitem'),
    enterItem: makePath(TAB_NAME.items, 'enteritem'),
    findItem: makePath(TAB_NAME.items, 'finditem'),
    item: makePath(TAB_NAME.items, 'item'), // WIP: Change to support ID/barcode

    cartTab: makePath(TAB_NAME.cart),
    cartScan: makePath(TAB_NAME.cart, 'scan'),
    cartEnterItem: makePath(TAB_NAME.cart, 'enteritem'),
    cartSaveToPOS: makePath(TAB_NAME.cart, 'save'),
    cartEmptyAfterSaveToPOS: makePath(TAB_NAME.cart, 'save/empty'),
    cartEmpty: makePath(TAB_NAME.cart, 'empty'),

    paymentsTab: makePath(TAB_NAME.payments),
    newPayments: makePath(TAB_NAME.payments, 'newpayments'),
    manualPayments: makePath(TAB_NAME.payments, 'manualpayments'),
    reviewPayments: makePath(TAB_NAME.payments, 'reviewPayments'),
    reviewPaymentsDetails: makePath(TAB_NAME.payments, 'reviewPaymentsDetails'),
    merchantID: makePath(TAB_NAME.payments, 'merchantID'),

    settingsTab: makePath(TAB_NAME.settings),
    accounts: makePath(TAB_NAME.settings, 'accounts'),
    editAccount: makePath(TAB_NAME.settings, 'editAccount'),
    addAccount: makePath(TAB_NAME.settings, 'addAccount'),
    preferences: makePath(TAB_NAME.settings, 'preferences'),
    about: makePath(TAB_NAME.settings, 'about'),
    help: makePath(TAB_NAME.help, 'help') 
})

const initialState = {
    paths: ['/']
}

/*****************************************************************************/
// Actions

const PATH_PUSH = '/path/PATH_PUSH'
const PATH_GO_BACK = '/path/PATH_GO_BACK'
const PATH_REPLACE = '/path/PATH_REPLACE'

/*****************************************************************************/
// Action Creators

export const pathPush = path => {
    if (path === PATHS.goBack) return pathGoBack()

    return {
        type: PATH_PUSH,
        path: path,
    }
}

export const pathGoBack = () => ({
    type: PATH_GO_BACK,
})

export const pathReplace = path => ({
    type: PATH_REPLACE,
    path: path,
})

/*****************************************************************************/
// Reducer

export default function pathReducer(state = initialState, action) {
    switch (action.type) {
        case PATH_PUSH: return updateOnPathPush(state, action)
        case PATH_GO_BACK: return updateOnPathGoBack(state, action)
        case PATH_REPLACE: return updateOnPathReplace(state, action)
        default: return state
    }
}

const updateOnPathPush = (state, action) => {
    return updateObject(state, { paths: [...state.paths, action.path] })
}

const updateOnPathGoBack = (state, action) => {
    if (state.paths.length === 1) return state
    
    const paths = state.paths.slice(0, state.paths.length - 1)
    return updateObject(state, { paths: paths })
}

const updateOnPathReplace = (state, action) => {
    const paths = [...state.paths]
    paths[paths.length - 1] = action.path
    return updateObject(state, { paths: paths })
}

/*****************************************************************************/
// Utility Functions

export const pathIsExactly = (pathState, path) => {
    const currentPath = pathState.paths[pathState.paths.length - 1]
    return path === currentPath
}
export const pathIsWithin = (pathState, path) => {
    const subPath = pathState.paths[pathState.paths.length - 1].substring(0, path.length)
    return path === subPath
}
