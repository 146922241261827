import { createStore, applyMiddleware } from 'redux'
import rootReducer from '../reducers/rootReducer'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import QuaggaMiddleware from '@ericblade/quagga2-redux-middleware'

// const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk, QuaggaMiddleware)))
const store = createStore(rootReducer, applyMiddleware(thunk, QuaggaMiddleware))

export default store
