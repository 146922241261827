import { updateObject } from '../utility'

/*****************************************************************************/
// Module data

const SET_TOOLTIP = '/tooltip/SET_TOOLTIP'

const initialState = {
    showingAccountTooltip: false,
    showingCartTooltip: false,
    preventAccountTooltip: false,
    preventCartTooltip: false,
}

/*****************************************************************************/
// Action Creators

export const setTooltip = (tab, showTooltip) => {
    let payload = null
    switch(tab){
        case "cart":
            payload = {
                showingCartTooltip: showTooltip,
                preventCartTooltip: true
            }
            break
        case "account":
            payload = {
                showingAccountTooltip: showTooltip,
                preventAccountTooltip: true
            }
            break
        default:
            return
    }

    return {
        type: SET_TOOLTIP,
        payload: payload
    }
}

/*****************************************************************************/
// Reducer

export default function tooltipReducer(state = initialState, action) {
    switch (action.type) {
        case SET_TOOLTIP: return updateOn_SET_TOOLTIP(state, action)
        default: return state
    }
}

const updateOn_SET_TOOLTIP = (state, action) => {
    return updateObject(state, { ...action.payload })
}

