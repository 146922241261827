export class Account {
    constructor(
        accountName,
        hostName,
        userName,
        password,
        key,
        dashStoreNumber,
        inventoryStoreNumber,
        priceStoreNumber,
        paymentStoreNumber,
        offlineMid,
        isGuest,
        isPending
    ) {
        this.accountName = accountName || ''
        this.hostName = hostName || ''
        this.userName = userName || ''
        this.password = password || ''
        this.key = key || Math.random().toString(36).substring(7)
        this.dashStoreNumber = dashStoreNumber || 0
        this.inventoryStoreNumber = inventoryStoreNumber || 1
        this.priceStoreNumber = priceStoreNumber || 1
        this.paymentStoreNumber = paymentStoreNumber || 1
        this.offlineMid = offlineMid || {unlockCode: "", merchantIdentifier: "", storeName: "", expiryDate: ""}
        this.offlinePaymentStores = []
        this.isGuest = isGuest || false
        this.isPending = isPending || true
    }
}

export default Account
