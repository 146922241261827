import axios from 'axios'
import log from './log'
import uniformPayUrl from './upUrl'

export const apiUpSendEmail = async (DeviceID, MerchantAlias, Recipient, Body, doneCallback, failCallback) => {
    var url = uniformPayUrl + '/apiUpSendEmail'
    axios({
        url: url,
        method: 'post',
        data: {
            DeviceID: DeviceID,
            MerchantAlias: MerchantAlias,
            Recipient: Recipient,
            EmailType: 29,
            Body: Body,
        }
    }).then((response) => {
        log(response.data)
        var res = response.data.apiUpSendEmail_response
        var success = res.Success
        var Error = res.Error

        if (success) {
            doneCallback()
        }
        else {
            failCallback(Error)
        }

    }).catch((error) => {
        failCallback(error)
    })
}

export default apiUpSendEmail