import { updateObject } from '../../../redux/utility'
import { PATHS } from '../../../redux/reducers/pathReducer'

/*****************************************************************************/
// Module data

const DISABLE_TAB = '/tabBar/DISABLE_TAB'
const ENABLE_TAB = '/tabBar/ENABLE_TAB'
const SET_TAB = '/tabBar/SET_TAB'

export const TABBAR_TAB = Object.freeze({
    dashboard: 0,
    items: 1,
    cart: 2,
    payments: 3,
    settings: 4,
})

const initialState = {
    tab: TABBAR_TAB.dashboard,
    disabledDashboard: false,
    disabledItems: false,
    disabledCart: false,
    disabledPayments: false,
    disabledSettings: false,
}

/*****************************************************************************/
// Action Creators

export const disableTab = tab => ({
    type: DISABLE_TAB,
    tab: tab,
})

export const enableTab = tab => ({
    type: ENABLE_TAB,
    tab: tab,
})

export const setTab = (tab, pathDirector, afterPath) => {
    return (dispatch) => {
        if (!pathDirector) return

        pathDirector.push(tabPath(tab))
        if (afterPath) pathDirector.push(afterPath)
        dispatch({
            type: SET_TAB,
            tab: tab,
        })
    }
}

/*****************************************************************************/
// Reducer

export default function tabBarReducer(state = initialState, action) {
    switch (action.type) {
        case DISABLE_TAB: return reduceDisableTab(state, action, true)
        case ENABLE_TAB: return reduceDisableTab(state, action, false)
        case SET_TAB: return reduceSetTab(state, action)
        default: return state
    }
}

const reduceDisableTab = (state, action, disabled) => {  // Used by DISABLE_TAB and ENABLE_TAB, by way of "disabled" parameter
    switch (action.tab) {
        case TABBAR_TAB.dashboard: return updateObject(state, { disabledDashboard: disabled })
        case TABBAR_TAB.items: return updateObject(state, { disabledItems: disabled })
        case TABBAR_TAB.cart: return updateObject(state, { disabledCart: disabled })
        case TABBAR_TAB.settings: return updateObject(state, { disabledSettings: disabled })
        default: return state
    }
}

const reduceSetTab = (state, action) => {
    return updateObject(state, { tab: action.tab })
}


/*****************************************************************************/
// Utility Functions

export const tabPath = tab => {
    const tabPaths = [
        PATHS.dashboardTab,
        PATHS.itemsTab,
        PATHS.cartTab,
        PATHS.paymentsTab,
        PATHS.settingsTab,
    ]
    return tabPaths[tab]
}
