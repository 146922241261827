import React from 'react'
import PropTypes from 'prop-types';
import { Button } from '@mui/material'
import clsx from 'clsx';
// import { withStyles } from '@material-ui/core/styles';

// const styles = {
//     root: {
//         minWidth: "90px",
//         color: "white"
//     },
// };

const DialogButton = (props) => {
    const { classes, children, className, ...other } = props

    return(
        <Button 
            color={props.color || "primary"} 
            variant={props.variant || "contained"} 
            // className={clsx(classes.root, className)} 
            className={className}
            sx={{color: "white", fontSize: "14px", fontFamily: "Open Sans"}} 
            {...other}
        >
            {children}
        </Button>
    )
}

DialogButton.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired
}

export default DialogButton;