import React from 'react'
import { connect } from 'react-redux'
import TabBar from './TabBar'
import Header from '../Header/Header'
import TabContainer from './TabContainer'
import { handleOrientationChange } from '../../../redux/reducers/dimensionsReducer'
import Toast from '../../Toast/Toast'
import InstallAndroidDialog from '../SettingsTab/InstallPWA/InstallAndroidDialog/InstallAndroidDialog'
import InstallIOSDialog from '../SettingsTab/InstallPWA/InstallIOSDialog/InstallIOSDialog'
import ErrorDialog from '../../ErrorDialog/ErrorDialog'

const AppLayout = (props) => {

    const { handleOrientationChange } = props

    //Update header/footer sizes on orientation change.
    React.useEffect(() => {
        window.addEventListener('orientationchange', function () {
            handleOrientationChange()
        });
    }, [handleOrientationChange])

    React.useEffect(() => {
        handleOrientationChange()
    }, [handleOrientationChange])


    return (
        <div>
            <Header/>
            <TabContainer/>
            <TabBar/>
            <Toast />
            <InstallAndroidDialog />
            <InstallIOSDialog />
            <ErrorDialog/>
        </div>
    )
}

const mapDispatchToProps = dispatch => ({
    handleOrientationChange: () => dispatch(handleOrientationChange()),
})

export default connect(null, mapDispatchToProps)(AppLayout)