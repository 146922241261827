// Session represents the user's connection state to the server

export class Session {
    constructor(apiKey = '', apiToken = '', sessionId = '', deviceId = '', deviceName = '', deviceModel = '', devicePlatform = '', deviceVersion = '') {
        this.apiKey = apiKey
        this.apiToken = apiToken
        this.sessionId = sessionId
        this.deviceId = deviceId
        this.deviceName = deviceName
        this.deviceModel = deviceModel
        this.devicePlatform = devicePlatform
        this.deviceVersion = deviceVersion
        this.programMode = 'USMOBILE'  // vs RDMOBILE, perhaps later
    }
}

export default Session