import React from 'react';
import { Typography } from "@mui/material"

const SubHeaderText = (props) => {
    const {children, style, ...other } = props;
    return (
        <Typography color={"textPrimary"} style={{fontSize: "16px !important", fontWeight: "bold", fontFamily: "Open Sans", ...style}}  {...other}>
            {children}
        </Typography>
    );
};

export default SubHeaderText;