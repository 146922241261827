import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './components/App/App'
import { Provider } from 'react-redux'
import store from './redux/store/store'
import ReactGA from 'react-ga4'

ReactGA.initialize('G-V8RC8351RG')

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
	<Provider store={store}>
		<App />
	</Provider>
)
