// import accountsReducer from '../../components/Tabs/SettingsTab/Accounts/accountsReducer'
// import getSelectedAccount from '../../components/Tabs/SettingsTab/Accounts/getSelectedAccount'
import getSelectedAccount from '../../components/Tabs/SettingsTab/Accounts/getSelectedAccount'
import isNonBlankString from '../../utils/isNonBlankString'
import { updateObject } from '../utility'
import { editAccount } from './accountsReducer'
import { setPaymentStores } from './paymentsReducer'
import log from '../../utils/log'
/*****************************************************************************/
// Module data

const STORES_SET_FROM_LOGIN1 = '/stores/STORES_SET_FROM_LOGIN1'
const STORES_SET_FROM_LOGIN2 = '/stores/STORES_SET_FROM_LOGIN2'
const STORES_FORMAT_STORE = '/stores/STORES_FORMAT_STORE'
const STORES_SET_FROM_ACCOUNT_DIALOG = '/stores/STORES_SET_FROM_ACCOUNT_DIALOG'

const blankStore = {
	name: '',
	address: '',
	address2: '',
	city: '',
	state: '',
	postalCode: '',
	cityStatePostalCode: '',
	phone: '',
}

const initialState = {
	stores: [],
	store: { ...blankStore },
	numberOfStores: 0,
	numberOfStoresText: '',
	companyNameText: '',
	storeName: '',

	storesFromAccountDialog: [],
	paymentStores: [],
}

const formatCityStatePostalCode = (city, state, postalCode) => {
	if (isNonBlankString(city)) {
		return city + ', ' + state + '  ' + postalCode
	} else {
		return ''
	}
}

/*****************************************************************************/
// Action Creators

export const storesSetFromLogin1 = (data) => {
	// Store List
	const stores = data.STORES
	if (Array.isArray(stores)) {
		for (const store of stores) {
			store.cityStatePostalCode = formatCityStatePostalCode(
				store.StoreCity,
				store.StoreState,
				store.StorePostalCode
			)
		}
	}
	log('storesSetFromLogin1', data)

	return {
		type: STORES_SET_FROM_LOGIN1,
		payload: {
			stores: stores,
			store: { ...blankStore },
		},
	}
}

export const storesSetFromLogin2 = (data) => {
	// Make apiLogin return "VALID_STORES" info with list of stores the user can access
	// Options store list is reduced to include only those that are valid
	// (The user may have previously selected a store to which they no longer have access, or is deleted)
	// apiDashboardInfo doesn't provide info for stores that the user cannot access
	// (That could change after mobile account creation, so it's required regardless)
	return (dispatch, getState) => {
		const selectedAccount = getSelectedAccount(getState().accountsReducer)
		const stores = getState().storesReducer.stores
		const inventoryStore = stores.find((store) => store.StoreNumber === selectedAccount.inventoryStoreNumber)

		var paymentStores = data.PAYMENT_STORES
		if (paymentStores && paymentStores !== null && paymentStores.length > 0) {
			//sort payment stores by store number
			paymentStores.sort((a, b) => a.StoreNumber - b.StoreNumber)

			//Set payment stores in the paymentReducer
			dispatch(setPaymentStores(paymentStores))

			//Look at selected account payment store number
			var currentPaymentStoreNumber = selectedAccount.paymentStoreNumber
			var foundValidPaymentStore = false

			//See if that storeNumber is in paymentStores
			for (var i = 0; i < paymentStores.length; i++) {
				if (currentPaymentStoreNumber === paymentStores[i].StoreNumber) {
					foundValidPaymentStore = true
					log(paymentStores[i])
				}
			}
			if (!foundValidPaymentStore) {
				// Set paymentStore in selected account to first valid payment store
				dispatch(editAccount({ ...selectedAccount, paymentStoreNumber: paymentStores[0].StoreNumber }, false))
			}
		} else {
			dispatch(editAccount({ ...selectedAccount, paymentStoreNumber: 0 }, false))
			dispatch(setPaymentStores([]))
		}

		//TODO: MAKE SURE THE INDEX IS NOT BEING SHOWN ON DASHBOARD
		const numberOfStoresText =
			data.NUMBEROFSTORES > 1 && selectedAccount.dashStoreNumber !== 0
				? ' (Store #' + selectedAccount.dashStoreNumber + ')'
				: ' (All Stores)'

		const store = !inventoryStore
			? { ...blankStore }
			: {
					name: inventoryStore.Store,
					address: inventoryStore.StoreAddress,
					address2: inventoryStore.StoreAddress2,
					city: inventoryStore.StoreCity,
					state: inventoryStore.StoreState,
					postalCode: inventoryStore.StorePostalCode,
					cityStatePostalCode: formatCityStatePostalCode(
						inventoryStore.StoreCity,
						inventoryStore.StoreState,
						inventoryStore.StorePostalCode
					),
					phone: inventoryStore.StorePhone,
			  }

		log('storesSetFromLogin2', data, selectedAccount, stores, inventoryStore, numberOfStoresText, store)

		dispatch({
			type: STORES_SET_FROM_LOGIN2,
			payload: {
				numberOfStores: data.NUMBEROFSTORES,
				numberOfStoresText: numberOfStoresText,
				companyNameText: data.COMPANY,
				storeName: data.STORE_NAME,
				store: store,
				paymentStores: data.PAYMENT_STORES || [],
			},
		})
	}
}

export const storesSetFromAccountDialog = (data) => {
	return {
		type: STORES_SET_FROM_ACCOUNT_DIALOG,
		payload: data,
	}
}

/*****************************************************************************/
// Reducer

export default function storesReducer(state = initialState, action) {
	// log('storesReducer/action.type', action.type)
	switch (action.type) {
		case STORES_SET_FROM_LOGIN1:
			return updateOn_STORES_SET_FROM_LOGIN1(state, action)
		case STORES_SET_FROM_LOGIN2:
			return updateOn_STORES_SET_FROM_LOGIN2(state, action)
		case STORES_FORMAT_STORE:
			return updateOn_STORES_FORMAT_STORE(state, action)
		case STORES_SET_FROM_ACCOUNT_DIALOG:
			return updateOn_STORES_SET_FROM_ACCOUNT_DIALOG(state, action)
		default:
			return state
	}
}

const updateOn_STORES_SET_FROM_LOGIN1 = (state, action) => {
	return updateObject(state, { ...action.payload })
}

const updateOn_STORES_SET_FROM_LOGIN2 = (state, action) => {
	return updateObject(state, { ...action.payload })
}

const updateOn_STORES_FORMAT_STORE = (state, action) => {
	return updateObject(state, { store: action.store })
}

const updateOn_STORES_SET_FROM_ACCOUNT_DIALOG = (state, action) => {
	return updateObject(state, { storesFromAccountDialog: action.payload })
}
