import { updateObject } from '../utility'
import { loadFromLocalStorage, saveToLocalStorage } from '../../utils/localStorage'
import { setDeviceID } from './loginReducer'
import { isIOS, isAndroid } from 'react-device-detect'
import axios from 'axios'
import { showToast } from '../../components/Toast/toastReducer'
import { loadMIDs } from './paymentsReducer'
import log from '../../utils/log'
import { logActivity } from './accountsReducer'
import { LOG_TYPES } from '../../logging/logTypes'

/*****************************************************************************/
// Module data
const SET_DISPLAY_MODE = '/pwaReducer/SET_DISPLAY_MODE'
const CAPTURE_INSTALL_EVENT = '/pwaReducer/CAPTURE_INSTALL_EVENT'

const INSTALL_ANDROID_PWA_SHOW = '/pwaReducer/INSTALL_ANDROID_PWA_SHOW'
const INSTALL_ANDROID_PWA_HIDE = '/pwaReducer/INSTALL_ANDROID_PWA_HIDE'

const INSTALL_IOS_PWA_SHOW = '/pwaReducer/INSTALL_IOS_PWA_SHOW'
const INSTALL_IOS_PWA_HIDE = '/pwaReducer/INSTALL_IOS_PWA_HIDE'

const UPDATE_PWA_SHOW = '/pwaReducer/UPDATE_PWA_SHOW'
const UPDATE_PWA_HIDE = '/pwaReducer/UDPATE_PWA_HIDE'

const INSTALLED_PWA = '/pwaReducer/INSTALLED_PWA'
const BLOCK_PWA = '/pwaReducer/BLOCK_PWA'
const UNBLOCK_PWA = '/pwaReducer/UNBLOCK_PWA'
const DETECT_DEVICE = '/pwaReducer/DETECT_DEVICE'

const SET_UPDATE = '/pwaReducer/SET_UPDATE'
const SET_SW = '/pwaReducer/SET_SW'
const SET_VERSION = '/pwaReducer/SET_VERSION'
const SHOW_WELCOME_MESSAGE = '/pwaReducer/SHOW_WELCOME_MESSAGE'

const SET_SELECTED_CAMERA = '/pwaReducer/SET_SELECTED_CAMERA'
const SET_CAMERAS = '/pwaReducer/SET_CAMERAS'

const SET_TITLE = '/pwaReducer/SET_TITLE'

export const DISPLAY_MODE = Object.freeze({
	stand_alone: 'stand alone',
	browser: 'browser',
})

export const OPERATING_SYSTEM = Object.freeze({
	android: 'android',
	ios: 'ios',
})

function consoleLogPWA(input) {
	log('%c[pwaReducer] ' + input, 'color: blue; background-color: yellow;')
}

function shouldPromptAgain() {
	let now = new Date()
	let recentlyPrompted = new Date(loadFromLocalStorage('installationPrompt'))
	let oneday = 60 * 60 * 24 * 1000
	let lessThanOneDay = now - recentlyPrompted < oneday
	return !lessThanOneDay
}

const initialState = {
	install_ios_open: false,
	install_android_open: false,
	update_pwa_open: false,
	ios_dialog_clicked: false,
	installable: false,
	prompted: false,
	install_event: null,
	update_available: false,
	prevent_dialog: false,
	display_mode: null,
	os: null,
	perm_blocked: false,
	sw: null,
	version: null,

	selectedCamera: null,
	cameras: [],

	title: '',
}

/*****************************************************************************/
// Action Creators

//Android/Windows Dialog Controllers
export const openAndroidPWADialog = () => ({
	type: INSTALL_ANDROID_PWA_SHOW,
	payload: { install_android_open: true },
})

export const hideAndroidPWADialog = () => ({
	type: INSTALL_ANDROID_PWA_HIDE,
	payload: { install_android_open: false },
})

//IOS Dialog Controllers
export const openIOSPWADialog = (clicked) => ({
	type: INSTALL_IOS_PWA_SHOW,
	payload: { install_ios_open: true, ios_dialog_clicked: clicked },
})

export const hideIOSPWADialog = () => ({
	type: INSTALL_IOS_PWA_HIDE,
	payload: { install_ios_open: false },
})

//Update Dialog Controllers
export const openUpdatePWADialog = () => ({
	type: UPDATE_PWA_SHOW,
	payload: { update_pwa_open: true },
})

export const closeUpdatePWADialog = () => ({
	type: UPDATE_PWA_HIDE,
	payload: { update_pwa_open: false },
})

//clean up
export const captureInstallEvent = (event) => {
	return (dispatch, getState) => {
		let prompted = getState().pwaReducer.prompted
		// if(!prompted && shouldPromptAgain()){
		if (!prompted) {
			//mark as prompted
			saveToLocalStorage('installationPrompt', String(new Date()))
			dispatch(openAndroidPWADialog())
		}
		dispatch({
			type: CAPTURE_INSTALL_EVENT,
			payload: {
				install_event: event,
				installable: true,
				os: OPERATING_SYSTEM.android,
				prompted: true,
			},
		})
	}
}

export const installedPWA = () => ({
	type: INSTALLED_PWA,
	payload: { installable: false },
})

export const blockPWA = () => {
	saveToLocalStorage('isBlockPWA', 'true')
	return {
		type: BLOCK_PWA,
		payload: { isBlockPWA: true, perm_blocked: true },
	}
}

export const unblockPWA = () => {
	return {
		type: UNBLOCK_PWA,
		payload: { isBlockPWA: false },
	}
}

export const loadPWASettings = () => {
	return (dispatch, getState) => {
		dispatch(getAppVersion())
		dispatch(showWelcomeMessage())
		dispatch(loadMIDs())

		let isBlockPWA = loadFromLocalStorage('isBlockPWA') || false
		if (isBlockPWA === 'true') {
			isBlockPWA = true
		}
		dispatch({
			type: BLOCK_PWA,
			payload: { isBlockPWA: isBlockPWA, perm_blocked: isBlockPWA },
		})
	}
}

export const detectDevice = () => {
	return (dispatch, getState) => {
		log('%cTIME ZONE: ', 'color: gold;')
		const tz = Intl.DateTimeFormat().resolvedOptions().timeZone

		let deviceID = loadFromLocalStorage('deviceID')
		dispatch(setDeviceID(deviceID))

		let os

		if (isIOS && !navigator.standalone) {
			os = OPERATING_SYSTEM.ios
			if (shouldPromptAgain()) {
				saveToLocalStorage('installationPrompt', String(new Date()))
				dispatch(openIOSPWADialog(false))
			}
		} else {
			os = OPERATING_SYSTEM.android
		}
		dispatch({
			type: DETECT_DEVICE,
			payload: { os: os, timeZone: tz },
		})
	}
}

export const getDisplayMode = () => {
	return (dispatch, getState) => {
		let mode = null
		let isStandAlone =
			window.matchMedia('(display-mode: standalone)').matches ||
			window.navigator.standalone ||
			document.referrer.includes('android-app://')

		if (isStandAlone) {
			mode = DISPLAY_MODE.stand_alone
			consoleLogPWA('Running as Stand Alone application.')
		} else {
			mode = DISPLAY_MODE.browser
			consoleLogPWA('Running from Browser.')
		}
		dispatch(setDisplayMode(mode))
	}
}

const setDisplayMode = (mode) => ({
	type: SET_DISPLAY_MODE,
	payload: { display_mode: mode },
})

export const setUpdate = (update) => {
	return (dispatch, getState) => {
		//See if it is the first time ran.
		let updatedBefore = Boolean(loadFromLocalStorage('updatedBefore'))

		if (updatedBefore) {
			consoleLogPWA('Not the first time updating the app ', updatedBefore)
			consoleLogPWA(update ? 'There is an update available!' : 'There is not an update available.')
			if (!update) {
				return
			}
			dispatch(openUpdatePWADialog())
			dispatch({
				type: SET_UPDATE,
				payload: { update_available: update },
			})
		} else {
			consoleLogPWA('First time installing app', updatedBefore)
			saveToLocalStorage('updatedBefore', 'true')
		}
	}
}

export const promptForInstall = () => {
	return (dispatch, getState) => {
		let deferredPrompt = getState().pwaReducer.install_event
		deferredPrompt.prompt()
		dispatch(logActivity(LOG_TYPES.installation, 'Prompting user to install to their device.', true))
		deferredPrompt.userChoice.then((choiceResult) => {
			if (choiceResult.outcome === 'accepted') {
				consoleLogPWA('The user accepted the installation prompt.')
				dispatch(installedPWA())
			} else {
				consoleLogPWA('The user rejected the installation prompt.')
			}
		})
	}
}

export const setSW = (sw) => {
	return (dispatch, getState) => {
		let updatedBefore = Boolean(loadFromLocalStorage('updatedBefore'))

		if (updatedBefore) {
			dispatch({
				type: SET_DISPLAY_MODE,
				payload: { sw: sw },
			})
		}
	}
}

export const registerServiceWorker = () => {
	return (dispatch, getState) => {
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker
				.register('/serviceworker.js', { scope: '/' })
				.then((reg) => {
					reg.addEventListener('updatefound', () => {
						navigator.serviceWorker.ready.then(function (registration) {
							registration
								.update()
								.then(function () {
									log('Checked for update')
								})
								.catch(function (error) {
									console.error('Update failed', error)
								})
						})
					})
				})
				.catch((err) => {
					log(
						'%cSERVICE WORKER: The service worker was not registered.',
						'color: red; background-color: black;'
					)
					log(err)
				})
			navigator.serviceWorker.addEventListener('controllerchange', () => {
				axios({
					url: '/get-SW-Version',
					method: 'get',
					timeout: 500,
				})
					.then((response) => {
						dispatch(showToast('Updated to version ' + response.data, 'success', 3000))
					})
					.catch((error) => {
						log('Unable to get the Service Worker version.', error)
					})
			})
		}
	}
}

export const updateSW = () => {
	return (dispatch, getState) => {
		let sw = getState().pwaReducer.sw
		if (sw.state === 'installed') {
			sw.postMessage('skipWaiting')
			window.location.reload()
		}
	}
}

export const getAppVersion = () => {
	return (dispatch, getState) => {
		axios({
			url: '/get-SW-Version',
			method: 'get',
			timeout: 500,
		})
			.then((response) => {
				dispatch({
					type: SET_VERSION,
					payload: { version: response.data },
				})
			})
			.catch((error) => {
				log('Unable to get the Service Worker version.', error)
			})
	}
}

export const showWelcomeMessage = () => {
	let showWelcomeMessage = loadFromLocalStorage('showWelcomeMessage')
	let payload
	if (!showWelcomeMessage) {
		payload = true
		saveToLocalStorage('showWelcomeMessage', 'false')
	} else {
		payload = false
		saveToLocalStorage('showWelcomeMessage', 'false')
	}

	return (dispatch, getState) => {
		dispatch({
			type: SHOW_WELCOME_MESSAGE,
			payload: { showWelcomeMessage: payload },
		})
	}
}

export const setTitle = (title) => {
	return (dispatch, getState) => {
		dispatch({
			type: SET_TITLE,
			payload: { title: title },
		})
	}
}

/*****************************************************************************/
// Reducer

export default function pwaReducer(state = initialState, action) {
	switch (action.type) {
		case CAPTURE_INSTALL_EVENT:
			return updatePWAState(state, action)
		case INSTALL_ANDROID_PWA_SHOW:
			return updatePWAState(state, action)
		case INSTALL_ANDROID_PWA_HIDE:
			return updatePWAState(state, action)
		case INSTALL_IOS_PWA_SHOW:
			return updatePWAState(state, action)
		case INSTALL_IOS_PWA_HIDE:
			return updatePWAState(state, action)
		case UPDATE_PWA_SHOW:
			return updatePWAState(state, action)
		case UPDATE_PWA_HIDE:
			return updatePWAState(state, action)
		case INSTALLED_PWA:
			return updatePWAState(state, action)
		case BLOCK_PWA:
			return updatePWAState(state, action)
		case SET_DISPLAY_MODE:
			return updatePWAState(state, action)
		case SET_UPDATE:
			return updatePWAState(state, action)
		case UNBLOCK_PWA:
			return updatePWAState(state, action)
		case DETECT_DEVICE:
			return updatePWAState(state, action)
		case SET_SW:
			return updatePWAState(state, action)
		case SET_VERSION:
			return updatePWAState(state, action)
		case SHOW_WELCOME_MESSAGE:
			return updatePWAState(state, action)
		case SET_SELECTED_CAMERA:
			return updatePWAState(state, action)
		case SET_CAMERAS:
			return updatePWAState(state, action)
		case SET_TITLE:
			return updatePWAState(state, action)
		default:
			return state
	}
}

const updatePWAState = (state, action) => {
	return updateObject(state, action.payload)
}
