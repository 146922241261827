import React from 'react';
import { connect } from 'react-redux'
import { Box, Grid } from "@mui/material"
import './Header.css'

const Header = (props) => {
    return (
        <Box 
            style={{
                height: props.header.height,
                flexGrow: 1,
                width: "100%",
                zIndex: 50,
                margin: 0,
                backgroundColor: '#106FA2',
                alignItems: "center",
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                color: 'white',
                position: 'fixed',
                paddingTop: props.header.topPadding, 
            }}
            boxShadow={2}
        >
            <div style={{width: "100%"}}>
                <Grid container style={{display: 'flex', flexDirection: "row", justifyContent: "space-between", width: "100%", alignItems: "center"}}>
                    <Grid item xs={2} style={{display: "flex", justifyContent: "flex-start"}}>{props.headerComponents.left}</Grid>
                    <Grid 
                        item
                        xs={8} 
                        style={{
                            display: "flex", 
                            justifyContent: "center", 
                        }}
                    >
                        <div 
                            style={{
                                fontSize: "16px", 
                                fontWeight: "bold", 
                                lineHeight: "20px"
                            }}>
                            {props.headerComponents.title}
                        </div>
                    </Grid>
                    <Grid item xs={2} style={{display: "flex", justifyContent: "flex-end"}}>{props.headerComponents.right}</Grid>
                </Grid>
            </div>
        </Box>
    )
}

const mapStateToProps = state => ({
    title: state.pwaReducer.title,
    tab: state.tabBarReducer.tab,
    accounts: state.accountsReducer.accounts,
    selectedAccount: state.accountsReducer.selectedAccount,
    header: state.dimensionsReducer.header,
    headerComponents: state.dimensionsReducer.headerComponents
})


export default connect(mapStateToProps, null)(Header)