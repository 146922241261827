import isNonBlankString from "../utils/isNonBlankString"
import { toClaDate, toClaTime } from "../utils/claDateTime"

export class ApiCart {
    constructor(cart, cartName, userName) {
        if (!cart) return
        if (typeof cart !== 'object') return
        if (!Array.isArray(cart.details)) return

        if (!(isNonBlankString(cart.name) || isNonBlankString(cartName))) return

        this.isWebOrder = "1"
        this.uuid = cart.uuid
        this.userName = userName

        this.wcartRecord = {
            name: cart.name || cartName,
            date: toClaDate(cart.date),
            time: toClaTime(cart.date),
            line_items: 0,
            open: 1,
            qty_total: 1,
        }

        this.items = []
        for (let detail of cart.details) {
            const quantity = detail.quantity
            if (!(quantity > 0)) continue

            this.wcartRecord.line_items++
            this.wcartRecord.qty_total += quantity

            const price = parseFloat(detail.item.priceFormatted.replace('$', '').replace(/,/g, ""))
            const item_tot = quantity * price

            this.items.push({
                wcItemRecord: {
                    quantity: quantity,
                    barcode: detail.item.barcode,
                    manufacturer: detail.item.manufacturer,
                    style: detail.item.style,
                    item_size: detail.item.size,
                    item_des: detail.itemDescription,
                    retail: price,
                    price: price,
                    price_notax: price,
                    item_tot: item_tot,
                    item_tot_notax: item_tot,
                }
            })
        }
    }
}
