import React from 'react'
import { Snackbar, Alert } from "@mui/material"
import { connect } from 'react-redux'
import { hideToast } from './toastReducer'
import log from '../../utils/log'

const PWAAlert = props => (
    <Alert elevation={6} variant="filled" {...props} />
)

const Toast = props => {
    log('Toast Props', props)

    const handleClose = (event, reason) => {
        if (reason !== 'clickaway') {
            props.hideToast()
        }
    }

    return (
        <Snackbar 
            open={props.open} 
            autoHideDuration={props.duration} 
            onClose={handleClose} 
            style={{marginBottom: "6.5em"}}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <div>
                <PWAAlert onClose={handleClose} severity={props.severity}>
                    {props.message}
                </PWAAlert>
            </div>
        </Snackbar>
    )
}

const mapStateToProps = state => ({
    open: state.toastReducer.open,
    message: state.toastReducer.message,
    severity: state.toastReducer.severity,
    duration: state.toastReducer.duration,
})

const mapDispatchToProps = dispatch => ({
    hideToast: () => dispatch(hideToast()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Toast)