import React from 'react'
import { connect } from 'react-redux'
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import DialogHeader from '../../hoc/DialogHeader/DialogHeader'
import DialogButton from '../../hoc/Buttons/DialogButton'
import { hideErrorNotification } from '../../redux/reducers/loginReducer'

const ErrorDialog = (props) => {
   
    const handleClose = () => {
        props.hideErrorNotification()
    }
    
    return (
        <React.Fragment>
            {props.errorNotification.show &&
                <Dialog open={props.errorNotification.show}>
                    <DialogTitle>
                        <DialogHeader title={props.errorNotification.header || ""} onClick={handleClose}/>
                    </DialogTitle>
                    <DialogContent dividers style={{minWidth: "275px"}}> {props.errorNotification.body || ""}</DialogContent>
                    <DialogActions><DialogButton onClick={handleClose}>OK</DialogButton></DialogActions>
                </Dialog>}
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    errorNotification: state.loginReducer.errorNotification,
})

const mapDispatchToProps = dispatch => ({
    hideErrorNotification: (show) => dispatch(hideErrorNotification(show))
})

export default connect(mapStateToProps, mapDispatchToProps)(ErrorDialog)