import { updateObject } from '../utility'
import { setDeviceID } from './loginReducer'
import { isIOS, isAndroid } from 'react-device-detect'
import axios from 'axios'
import { showToast } from '../../components/Toast/toastReducer'
import { loadMIDs } from './paymentsReducer'
import log from '../../utils/log'
import { logActivity } from './accountsReducer'
import { LOG_TYPES } from '../../logging/logTypes'
import { enumerateVideoDevices, requestCameraPermission  } from '@ericblade/quagga2-redux-middleware';
import { saveToLocalStorage, loadFromLocalStorage } from '../../utils/localStorage'
/*****************************************************************************/
// Module data
const RECEIVE_VIDEO_DEVICES = '@quagga2/receiveVideoDevices'
const CAMERA_PERMISSION_SUCCESSFUL = '@quagga2/cameraPermissionSuccessful'
const CAMERA_NO_PERMISSION = '@quagga2/cameraNoPermission'
const SET_SCANNER_SETTINGS = '/scannerReducer/SET_SCANNER_SETTINGS'

const DEFAULT_SETTINGS = {
    videoDevices: [],
    cameraPermission: null,
    settings: {
        deviceId: null,
        width: 800,
        height: 600,
        locate: true,
        zoom: 1
    },
}

const initialState = DEFAULT_SETTINGS



/*****************************************************************************/
// Action Creators

// var accounts = loadFromLocalStorage('accounts')

export const getCameraPermission = () => {
    return (dispatch, getState) => {
        dispatch(logActivity(LOG_TYPES.scan, 'Requesting permission to use the camera.', false))
        dispatch(requestCameraPermission())
    }
}

export const getVideoDevices = () => {
    return (dispatch, getState) => {
        dispatch(logActivity(LOG_TYPES.scan, 'Enumerating video devices.', false))
        dispatch(enumerateVideoDevices())
    }
}


export const loadScannerSettings = () => {
    return (dispatch, getState) => {

        var scannerSettings = loadFromLocalStorage('scannerSettings')
        var loadedCameras = getState().scannerReducer.videoDevices
        var settings
        if(!scannerSettings){
            dispatch(logActivity(LOG_TYPES.scan, 'There were no saved settings.', false))
            settings = DEFAULT_SETTINGS.settings
            settings.deviceId = loadedCameras[0].deviceId
            saveToLocalStorage('scannerSettings', settings)
            dispatch({type: SET_SCANNER_SETTINGS, payload: settings})
        }
        else{
            dispatch(logActivity(LOG_TYPES.scan, 'Loaded scanner settings.', true))

            if(scannerSettings.deviceId === null){
                scannerSettings.deviceId = loadedCameras[0].deviceId
            }
            else if( scannerSettings.deviceId.length < 1){
                scannerSettings.deviceId = loadedCameras[0].deviceId
            }

            //Check the loaded settings
            if(
                "deviceId" in scannerSettings &&
                "locate" in scannerSettings &&
                "width" in scannerSettings && 
                "zoom" in scannerSettings && 
                "height" in scannerSettings
            ){
                dispatch({type: SET_SCANNER_SETTINGS, payload: scannerSettings})
            }
            else{
                dispatch(logActivity(LOG_TYPES.scan, 'Fixing the saved settings.', false))
                settings = DEFAULT_SETTINGS.settings
                settings.deviceId = loadedCameras[0].deviceId
                saveToLocalStorage('scannerSettings', DEFAULT_SETTINGS.settings)
                dispatch({type: SET_SCANNER_SETTINGS, payload: DEFAULT_SETTINGS.settings})
            }
        }
    }
}


export const setScannerSettings = (settings) => {
    return(dispatch, getState) => {
        try{
            saveToLocalStorage('scannerSettings', settings)
            dispatch({type: SET_SCANNER_SETTINGS, payload: settings})
            dispatch(logActivity(LOG_TYPES.scan, 'Saved scanner settings.', true))
        }
        catch{
            dispatch(showToast("Unable to save scanner settings.", "error", 3000))
            dispatch(logActivity(LOG_TYPES.scan, 'Unable to save scanner settings.', false))
        }
    }
}

/*****************************************************************************/
// Reducer


export default function scannerReducer(state = initialState, action) {
    switch (action.type) {
        case RECEIVE_VIDEO_DEVICES: return updateVideoDevices(state, action)
        case CAMERA_PERMISSION_SUCCESSFUL: return updateCameraPermissionSuccess(state, action)
        case CAMERA_NO_PERMISSION: return updateCameraNoPermission(state, action)
        case SET_SCANNER_SETTINGS: return updateScannerSettings(state, action)
        default: return state
    }
}

const updateVideoDevices = (state, action) => {
    return updateObject(state, { videoDevices: action.payload})
}

const updateCameraPermissionSuccess = (state, action) => {
    return updateObject(state, { cameraPermission: true})
}

const updateCameraNoPermission = (state, action) => {
    return updateObject(state, { cameraPermission: false})
}

const updateScannerSettings = (state, action) => {
    return updateObject(state, { settings: action.payload})
}


const updateState = (state, action) => {
    return updateObject(state, action.payload)
}