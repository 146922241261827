import axios from 'axios'
import log from './log'
import uniformPayUrl from './upUrl'

export const apiUpConfirmPaymentUnlock = async (deviceID, unlockCode, doneCallback, failCallback) => {
    var url = uniformPayUrl + '/apiUpConfirmPaymentUnlock'

    let req = {
        DeviceID: deviceID,
        UnlockCode: unlockCode
    }

    log("%c-----apiUpConfirmPaymentUnlock-----", "color: gold;")
    log(req)

    axios({
        url: url,
        method: 'post',
        data: req,
        timeout: 10000
    }).then(response => {
        log(response)
        if (response.data.apiUpConfirmPaymentUnlock_response.Success !== true) {
            failCallback(response.data.apiUpConfirmPaymentUnlock_response.Error)
            return
        }
        doneCallback(response.data.apiUpConfirmPaymentUnlock_response.MerchantID, response.data.apiUpConfirmPaymentUnlock_response.ExpiryDate)
    }).catch(error => {
        log(error)
        failCallback(error)
    })
}

export default apiUpConfirmPaymentUnlock