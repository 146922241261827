import axios from 'axios'
import log from './log'
import uniformPayUrl from './upUrl'

export const upReportTransactions = async (deviceKey, merchantAlias, merchant, dateRange, doneCallback, failCallback) => {
    var url = uniformPayUrl + '/apiUpReportTransactions'

    let req = {
        MerchantID: merchant,
        DeviceID: deviceKey,
        MerchantAlias: merchantAlias,
    }

    log("%c-----upReportTransactions-----", "color: gold;")
    log(req)

    axios({
        url: url,
        method: 'post',
        data: req,
        timeout: 5000
    }).then((response) => {
        log(response)
        try {
            if (response.data.apiUpReportTransactions_response.queue[0].ERRORDESCRIPTION) {
                failCallback(response.data.apiUpReportTransactions_response.queue[0].ERRORDESCRIPTION)
                return
            }
        }
        catch {
            doneCallback(response.data.apiUpReportTransactions_response)
        }
    }).catch((error) => {
        log(error)
        failCallback(error)
    })
}

export default upReportTransactions