import React, { lazy, Suspense } from 'react'
import { connect } from 'react-redux'
import Box from "@mui/material/Box"
import TabPanel from '../../../hoc/TabPanel/TabPanel'
import DialogLoading from '../../../hoc/Loading/DialogLoading'
import { TABBAR_TAB } from '../TabBar/tabBarReducer'

const TabContainer = ({tab}) => {

    const tabs = { ...TABBAR_TAB, current: tab }
    const DashboardTab = lazy(()=>import('../DashboardTab/DashboardTab'))
    const ItemsTab = lazy(()=>import('../ItemsTab/ItemsTab'))
    const CartTab = lazy(()=>import('../CartTab/CartTab'))
    const PaymentsTab = lazy(()=>import('../PaymentsTab/PaymentsTab'))
    const SettingsTab = lazy(()=>import('../SettingsTab/SettingsTab'))

    return (
        <Box>
            <TabPanel value={tabs.current} index={tabs.dashboard}>
                <Suspense fallback={<DialogLoading/>}>
                    <DashboardTab />
                </Suspense>
            </TabPanel>

            <TabPanel value={tabs.current} index={tabs.items}>
                <Suspense fallback={<DialogLoading/>}>                
                    <ItemsTab/>
                </Suspense>
            </TabPanel>

            <TabPanel value={tabs.current} index={tabs.cart}>
                <Suspense fallback={<DialogLoading/>}>
                    <CartTab />
                </Suspense>
            </TabPanel>

            <TabPanel value={tabs.current} index={tabs.payments}>
                <Suspense fallback={<DialogLoading/>}>
                    <PaymentsTab />
                </Suspense>
            </TabPanel>

            <TabPanel value={tabs.current} index={tabs.settings}>
                <Suspense fallback={<DialogLoading/>}>
                    <SettingsTab />
                </Suspense>
            </TabPanel>
        </Box>
    )
}

const mapStateToProps = state => ({
    tab: state.tabBarReducer.tab
})

export default connect(mapStateToProps, null)(TabContainer)
