import apiStore from './apiStore'
import sessionIdParm from './sessionIdParm'

export const apiCheckStockForAllStores = (hostName, session, doneCallback, failCallback) => {
	const url =
		hostName +
		'/CheckStockForAllStores?api=mobile' +
		'&_ajax_=1&apiToken=' +
		session.apiToken +
		sessionIdParm(session)

	apiStore({
		url: url,
		method: 'get',
		timeout: 8000,
	})
		.then((response) => {
			const data = response.data
			if (Array.isArray(data) || (data && Array.isArray(data.stores))) {
				const stores = Array.isArray(data) ? data : data.stores
				doneCallback(stores)
			} else {
				failCallback('Could not load stores tied to your account.')
			}
		})
		.catch((error) => {
			failCallback('Could not load stores tied to your account.')
		})
}

export default apiCheckStockForAllStores
