import axios from 'axios'
import log from './log'
import uniformPayUrl from './upUrl'

export const upReverseTransaction = async (deviceKey, merchantAlias, merchant, transactionID, totalAmount, doneCallback, failCallback) => {
    var url = uniformPayUrl + '/apiUpReverseTransaction'

    let req = {
        MerchantID: merchant,
        DeviceID: deviceKey,
        MerchantAlias: merchantAlias,
        TransactionID: transactionID,
        TotalAmount: totalAmount
    }

    log("%c-----upReverseTransactions-----", "color: gold;")
    log(req)

    axios({
        url: url,
        method: 'post',
        data: req,
        timeout: 5000
    }).then((response) => {
        log(response)
        if (response.data.apiUpReverseTransaction_response.IsSuccessful === false) {
            failCallback(response.data.apiUpReverseTransaction_response.Error)
            return
        }
        doneCallback(response)
    }).catch((error) => {
        log(error)
        failCallback(error)
    })
}

export default upReverseTransaction