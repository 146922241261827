import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

function TabPanel (props) {

    const { children, value, index, header, footer } = props;

    var tabStyle = {
        position: "fixed",
        top: header.height,
        width: "100vw",
        height: "calc(100vh - " + header.height + " - " + footer.height + ")", 
        overflow: "auto",
        paddingBottom: "100px" //Adding this due to additional ui overlays from mobile browsers.
    }

    return (
        <div hidden={value !== index} style={tabStyle} >
            {value === index && (
                <React.Fragment>
                    {children}
                </React.Fragment>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
const mapStateToProps = state => ({
    header: state.dimensionsReducer.header,
    footer: state.dimensionsReducer.footer,
})

export default connect(mapStateToProps, null)(TabPanel)