import axios from 'axios'
import log from './log'
import uniformPayUrl from './upUrl'

export const upAuthKeyPayment = async (manualPaymentsState, doneCallback, failCallback) => {
    var url = uniformPayUrl + '/apiUpAuthKeyPayment'

    let data = {
        MerchantID: manualPaymentsState.store.MerchantID,
        DeviceID: manualPaymentsState.deviceID,
        Amount: manualPaymentsState.paymentValue,
        MerchantAlias: manualPaymentsState.store.MerchantAlias
    }
    
    axios({
        url: url,
        method: 'post',
        data: data,
        timeout: 15000
    }).then((response) => {
        log("upAuthKey", response)
        var authKey = response.data.apiUpAuthKeyPayment_response.AuthenticationKey
        var testing = response.data.apiUpAuthKeyPayment_response.Testing
        var error = response.data.apiUpAuthKeyPayment_response.Error
        if (error) {
            failCallback(error)
        }
        else {
            if (authKey === "") {
                failCallback()
            }
            else {
                doneCallback(authKey, testing)
            }
        }
    }).catch((error) => {
        log(error)
        failCallback(error)
    })
}

export default upAuthKeyPayment