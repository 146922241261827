import React, { useEffect, Fragment, lazy, Suspense } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import './App.css'
// import TabBar from '../Tabs/TabBar/TabBar'
import TabBar from '../Tabs/TabLayout/TabBar'
import { connect } from 'react-redux'
import { loadAccounts, editAccount, logActivity } from '../../redux/reducers/accountsReducer'
import { loadPreferences } from '../Tabs/SettingsTab/Preferences/preferencesReducer'
import { LOGIN_MODE, login, openLoginForm } from '../../redux/reducers/loginReducer'
import { loadCart, setItemCount } from '../Tabs/CartTab/cartReducer'
import { pathPush, pathGoBack, pathReplace, PATHS } from '../../redux/reducers/pathReducer'
import {
	getDisplayMode,
	captureInstallEvent,
	loadPWASettings,
	detectDevice,
	registerServiceWorker,
} from '../../redux/reducers/pwaReducer'
import { Paper } from '@mui/material'
// import Login from '../Login/Login'
import { ThemeProvider } from '@mui/material/styles'
import darkTheme from '../../themes/darkTheme'
import lightTheme from '../../themes/lightTheme'
import { TABBAR_TAB, setTab } from '../Tabs/TabBar/tabBarReducer'
import { showToast } from '../Toast/toastReducer'
import getSelectedAccount from '../Tabs/SettingsTab/Accounts/getSelectedAccount'
import { disableTabs } from '../../redux/reducers/loginReducer'
import { selectAccountFromList } from '../../redux/reducers/accountsReducer'
import isNonBlankString from '../../utils/isNonBlankString'
import log from '../../utils/log'
import PageLoading from '../../hoc/Loading/PageLoading'
import AppLayout from '../Tabs/TabLayout/AppLayout'
import { LOG_TYPES } from '../../logging/logTypes'

const App = (props) => {
	const Login = lazy(() => import('../Login/Login'))
	// const TabBar = lazy(()=>import('../Tabs/TabBar/TabBar'))

	const {
		loadAccounts,
		loadPreferences,
		loadCart,
		login,
		loginMode,
		loadPWASettings,
		getDisplayMode,
		detectDevice,
		registerServiceWorker,
		cart,
		setItemCount,
	} = props

	const relogin = () => {
		if (loginMode === LOGIN_MODE.disconnected && props.accounts.length > 0) {
			if (props.accounts.length === 1 && props.accounts[0].isPending) {
				return
			}
			login(props.pathDirector)
		}
	}

	const captureInstallPrompt = () => {
		//Show ios safari how to download to home screen.
		window.addEventListener('beforeinstallprompt', (e) => {
			e.preventDefault()
			props.captureInstallEvent(e)
		})

		window.addEventListener('appinstalled', () => {
			log('INSTALL: Success')
		})
	}

	const captureErrors = () => {
		window.addEventListener(
			'error',
			function (e) {
				props.logActivity(LOG_TYPES.error, 'Unexpected error: ' + e.message, false)
			},
			true
		)
	}

	const [isValidAccount, setIsValidAccount] = React.useState(false)

	const handleGuestAccount = () => {
		props.disableTabs()
		var currentPath = props.pathState.paths[props.pathState.paths.length - 1]
		if (['/', '/dashboard'].includes(currentPath)) {
			props.setTab(TABBAR_TAB.settings, props.pathDirector)
			props.pathPush(PATHS.accounts)
		}
	}

	const checkForValidAccounts = () => {
		//No accounts. Stay on Login screen. Wait for new account to be created.
		if (props.accounts.length === 0) {
			setIsValidAccount(false)
			return
		}

		//Only pending accounts. Stay on Login screen.
		if (props.accounts.length === 1) {
			if (props.accounts[0].isPending) {
				setIsValidAccount(false)
				return
			}
		}

		//There are more than one account and none are pending.
		setIsValidAccount(true)

		if (!isNonBlankString(props.selectedAccount)) {
			var currentPath = props.pathState.paths[props.pathState.paths.length - 1]
			if (['/', '/dashboard'].includes(currentPath)) {
				props.setTab(TABBAR_TAB.settings, props.pathDirector)
				props.pathPush(PATHS.accounts)
			}
			props.disableTabs()
			return
		}

		if (props.loginMode === LOGIN_MODE.disconnected) {
			//If the account changed, and there is still one or more valid accounts, relogin.
			const account = getSelectedAccount(props.accountsReducer)
			if (account) {
				if (account.isGuest === true) {
					//Do not relogin a guest account.
					handleGuestAccount()
				} else {
					relogin()
				}
			}
		}
		return
	}

	//Run the following functions on startup
	function startUp() {
		log('%c----------Initialize start up process----------', 'color: lime; font-size: 14px;')

		//Load accounts from local storage. Causes checkForValidAccounts to be run.
		captureErrors()
		loadAccounts()
		registerServiceWorker()
		captureInstallPrompt()
		detectDevice()
		loadPreferences()
		loadCart()
		loadPWASettings()
		getDisplayMode()

		log('%c----------Finished start up process.----------', 'color: lime; font-size: 14px;')
	}

	//Start up only when the app is opened.
	useEffect(startUp, [])

	//Every time an account is created or removed, check for valid accounts.
	useEffect(() => {
		checkForValidAccounts()
	}, [props.accounts, props.selectedAccount])

	//Every time the cart changes, set the item count.
	useEffect(setItemCount, [cart])

	return (
		<Fragment>
			<ThemeProvider theme={props.preferences.darkMode ? darkTheme : lightTheme}>
				<CssBaseline />
				<Paper className='App' style={{ backgroundColor: props.darkMode ? '#515151' : '#F1F1F1' }}>
					{isValidAccount ? (
						<AppLayout />
					) : (
						<Suspense fallback={<PageLoading />}>
							<Login />
						</Suspense>
					)}
				</Paper>
				<Paper
					square
					className={'paperBackground'}
					style={{ backgroundColor: props.darkMode ? '#515151' : '#F1F1F1' }}
				/>
			</ThemeProvider>
		</Fragment>
	)
}

const mapStateToProps = (state) => ({
	name: state.accountsReducer.name,
	loginMode: state.loginReducer.mode,
	ios_dialog_clicked: state.pwaReducer.ios_dialog_clicked,
	cart: state.cartReducer.cart,
	darkMode: state.preferencesReducer.preferences.darkMode,
	accounts: state.accountsReducer.accounts,
	preferences: state.preferencesReducer.preferences,
	selectedAccount: state.accountsReducer.selectedAccount,
	accountsReducer: state.accountsReducer,
	pathState: state.pathReducer,
})

const mapDispatchToProps = (dispatch) => ({
	loadAccounts: () => dispatch(loadAccounts()),
	loadPreferences: () => dispatch(loadPreferences()),
	loadCart: () => dispatch(loadCart()),
	login: (pathDirector) => dispatch(login(pathDirector)),
	captureInstallEvent: (event) => dispatch(captureInstallEvent(event)),
	pathDirector: {
		push: (path) => dispatch(pathPush(path)),
		goBack: () => dispatch(pathGoBack()),
		replace: (path) => dispatch(pathReplace(path)),
	},
	getDisplayMode: () => dispatch(getDisplayMode()),
	loadPWASettings: () => dispatch(loadPWASettings()),
	detectDevice: () => dispatch(detectDevice()),
	registerServiceWorker: () => dispatch(registerServiceWorker()),
	setItemCount: () => dispatch(setItemCount()),
	openLoginForm: (pathDirector) => dispatch(openLoginForm(pathDirector)),
	editAccount: (account, requireReconnect) => dispatch(editAccount(account, requireReconnect)),
	setTab: (newTab, pathDirector) => dispatch(setTab(newTab, pathDirector)),
	pathPush: (path) => dispatch(pathPush(path)),
	showToast: (message, severity, duration) => dispatch(showToast(message, severity, duration)),
	disableTabs: () => dispatch(disableTabs()),
	selectAccountFromList: (key) => dispatch(selectAccountFromList(key)),
	logActivity: (type, message, success) => dispatch(logActivity(type, message, success)),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
