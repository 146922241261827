import apiStore from '../../../utils/apiStore'
import { updateObject } from '../../../redux/utility'
import { sessionIdParm } from '../../../utils/sessionIdParm'
import { loginServerAccessed } from '../../../redux/reducers/loginReducer'
import { loadFromLocalStorage } from '../../../utils/localStorage'
import { wait } from '../../../utils/wait'
import { getSelectedAccount } from '../SettingsTab/Accounts/getSelectedAccount'
import log from '../../../utils/log'

/*****************************************************************************/
// Module data

const DASHBOARD_SET_STATE = '/dashboard/DASHBOARD_SET_STATE'
const SET_DASHBOARD_STORES = '/dashboard/SET_DASHBOARD_STORES'

export const DASHBOARD_MODE = Object.freeze({
    initial: 'Initial', // very temporary on viewShown
    refreshing: 'Refreshing', // often ignored
    loading: 'Loading',
    polling: 'Polling',
    blank: 'Blank',
    dashboard: 'Dashboard', // employee user
    store: 'Store', // retail user
})

// const LOAD_RESPONSE = Object.freeze({

// })

const initialState = {
    selectedAccountKey: '',
    mode: DASHBOARD_MODE.initial,
    requestId: 0,
    pollingCountdown: 0,
    pollingCycleCount: 0,
    visible: {
        header: false,
        data: false,
        pullDownReminder: false,
        polling: false,
        // loading: false,
        loading: true,
    },
    summary: {
        todaysSales: '',
        myTodaysSales: '',
        avgTicket: '',
        myAvgTicket: '',
        numberOfTickets: '',
        myNumberOfTickets: '',
        monthThisYear: '',
        monthLastYear: '',
        myMonthThisYear: '',
        myMonthLastYear: '',
        ytdThisYear: '',
        ytdLastYear: '',
        myYtdThisYear: '',
        myYtdLastYear: '',
        accountsReceivable: '',
        accountsPayable: '',
        inventoryRetail: '',
        inventoryCost: '',
        openPOs: '',
        pctChangeMTD: '',
        pctChangeYTD: '',
        myPctChangeMTD: '',
        myPctChangeYTD: '',
    },
}

const debugColor = 'color: purple;'

/*****************************************************************************/
// Action Creators

export const dashboardLoad = () => {
    return async (dispatch, getState) => {

        const workingState = { ...getState().dashboardReducer }
        const selectedAccount = getSelectedAccount(getState().accountsReducer)
        const session = { ...getState().loginReducer.session }
        log('%cdashboardLoad/IN', debugColor, workingState, selectedAccount)

        const isDifferentAccount = newestKey => workingState.selectedAccountKey !== newestKey
        const isLoadingMode = mode => [DASHBOARD_MODE.loading, DASHBOARD_MODE.polling].includes(mode)
        const isNewRequest = isDifferentAccount(selectedAccount.key) || !isLoadingMode(workingState.mode)
        const isPollingCountdown = ({mode, pollingCountdown}) => mode === DASHBOARD_MODE.polling && pollingCountdown > 0

        if (isNewRequest) {
            workingState.pollingCycleCount = 0
            workingState.selectedAccountKey = selectedAccount.key
            workingState.mode = DASHBOARD_MODE.initial
            delete (workingState.summary)
        }

        const newMode = () => {
            if (session.isRetailUser) return DASHBOARD_MODE.store
            if (isLoadingMode(workingState.mode)) return workingState.mode
            return DASHBOARD_MODE.loading
        }

        const parms = {
            dispatch: dispatch,
            selectedAccount: selectedAccount,
            session: session,
            workingState: workingState,
            newMode: newMode(),
        }

        // If Loading/Polling, either call API or countdown for polling
        if (isLoadingMode(workingState.mode)) {
            if (isPollingCountdown(workingState)) {
                log('%cdashboardLoad - before wait', debugColor, parms)
                await wait(1000)
                workingState.pollingCountdown--
                log('%cdashboardLoad - after wait', debugColor, parms)
                if(workingState.pollingCountdown === 0){
                    workingState.pollingCycleCount++
                }
            } else {
                log('%cdashboardLoad - pre load', debugColor, parms)
                const loadResult = await load(parms)
                log('%cdashboardLoad - resolve load', debugColor, loadResult)
                parms.newMode = loadResult.newMode

                switch (loadResult.newMode) {
                    case DASHBOARD_MODE.dashboard:
                        log(loadResult.data)
                        workingState.summary = composeSummaryFromData(loadResult.data)
                        break
                    case DASHBOARD_MODE.polling:
                        workingState.pollingCountdown = 10
                        break
                    default: break
                }
            }
        }

        // Was another account selected while we were "thinking"?
        if (isDifferentAccount(loadFromLocalStorage('selectedAccount'))) return

        // Update state
        log('%cdashboardLoad - pre setState', debugColor, parms)
        setState(parms)
        log('%cdashboardLoad - post setState', debugColor, parms)

        // If we're done, then break
        if ([
            DASHBOARD_MODE.blank,
            DASHBOARD_MODE.store,
            DASHBOARD_MODE.dashboard,
        ].includes(workingState.mode)) {
            log('%cdashboardLoad - loop bottom break', debugColor, parms)
            return
        }
        // log('%cdashboardLoad - loop bottom continue', debugColor, parms)
        dispatch(dashboardLoad())
    }
}

/*****************************************************************************/
// Reducer

export default function dashboardReducer(state = initialState, action) {
    switch (action.type) {
        case DASHBOARD_SET_STATE: return updateOn_DASHBOARD_SET_STATE(state, action)
        case SET_DASHBOARD_STORES: return updateState(state, action)
        default: return state
    }
}

const updateOn_DASHBOARD_SET_STATE = (state, action) => {
    log('updateOn_DASHBOARD_SET_STATE', action.newState)
    return updateObject(state, action.newState)
}

const updateState = (state, action) => {
    return updateObject(state, { ...action.payload })
}

/*****************************************************************************/
// Module Functions

const setState = ({ newMode, dispatch, selectedAccount, session, workingState, data }) => {
    log('%csetState: prior mode=' + workingState.mode + '; new mode=' + newMode, debugColor, data);

    const setLoadingVisible = (isVisible, isPolling) => {
        log('%csetLoadingVisible: isVisible=' + isVisible, debugColor)
        workingState.visible.loading = isVisible
        workingState.visible.polling = isVisible && isPolling
        workingState.visible.header = !isVisible
    }

    const setDataVisible = isVisible => {
        //log('%csetDataVisible: isVisible=' + isVisible, debugColor)
        workingState.visible.header = isVisible
        workingState.visible.data = isVisible
        if (isVisible
            || workingState.mode === DASHBOARD_MODE.loading
            || workingState.mode === DASHBOARD_MODE.polling
            || session.isRetailUser) {
            workingState.visible.pullDownReminder = false
        } else {
            workingState.visible.pullDownReminder = true
        }
    }

    // Change the Mode
    workingState.mode = newMode
    switch (newMode) {
        case DASHBOARD_MODE.loading:
            setDataVisible(false)
            setLoadingVisible(true)
            break
        case DASHBOARD_MODE.polling:
            setLoadingVisible(true, true)
            break
        case DASHBOARD_MODE.blank:
            setLoadingVisible(false)
            setDataVisible(false)
            break
        case DASHBOARD_MODE.dashboard:
            setLoadingVisible(false)
            if (workingState.summary?.todaysSales) {
                setDataVisible(true)
            }
            break
        case DASHBOARD_MODE.store:
            break
        default:
            // alert('Unexpected newMode in setState: ' + newMode)
            break
    }

    dispatch({
        type: DASHBOARD_SET_STATE,
        newState: workingState
    })
}

const load = parms => {
    log('%cdashboardLoad - load', debugColor, parms)

    const apiGetDasboardInfo = ({ dispatch, selectedAccount, session, workingState }) => {
        log('apiGetDasboardInfo/IN: current=', workingState)

        if ([0, undefined].includes(workingState.requestId)) {
            workingState.requestId = Math.floor(Math.random() * 2e9) + 1 // Almost max for Clarion LONG
        }
        log('apiGetDasboardInfo: requestId', workingState.requestId)

        return apiStore({
            url: selectedAccount.hostName
                + "/apiGetDasboardInfo"
                + "?apiToken=" + session.apiToken
                + sessionIdParm(session)
                + "&sn=" + selectedAccount.dashStoreNumber
                + "&ri=" + workingState.requestId,
            method: 'get',
            timeout: 40000
        })
            .then(response => {
                log('apiGetDashboardInfo/then', response)
                dispatch(loginServerAccessed())
                if (response.data.AUTHOK === 0) {
                    log('apiGetDashboardInfo/then/A')
                    return { newMode: DASHBOARD_MODE.blank }
                } else if ([0, undefined].includes(response.data.REQUESTID)) {
                    workingState.requestId = 0
                    log('apiGetDashboardInfo/then/B')
                    return { newMode: DASHBOARD_MODE.dashboard, data: response.data }
                } else {
                    log('apiGetDashboardInfo/then/C')
                    return { newMode: DASHBOARD_MODE.polling }
                }
            })
            .catch(error => {
                log('apiGetDashboardInfo/catch', error)
                return { newMode: DASHBOARD_MODE.blank }
            })
    }

    return new Promise(resolve => resolve(apiGetDasboardInfo(parms)))
}

const composeSummaryFromData = data => ({
    todaysSales: data.TODAY,
    myTodaysSales: data.MYTODAY,
    avgTicket: data.AVGTICKET,
    myAvgTicket: data.MYAVGTICKET,
    numberOfTickets: data.NUMBEROFTICKETS,
    myNumberOfTickets: data.MYNUMBEROFTICKETS,
    monthThisYear: data.MONTHTHISYEAR,
    monthLastYear: data.MONTHLASTYEAR,
    myMonthThisYear: data.MYMONTHTHISYEAR,
    myMonthLastYear: data.MYMONTHLASTYEAR,
    ytdThisYear: data.YTDTHISYEAR,
    ytdLastYear: data.YTDLASTYEAR,
    myYtdThisYear: data.MYYTDTHISYEAR,
    myYtdLastYear: data.MYYTDLASTYEAR,
    accountsReceivable: data.CURRENTAR,
    accountsPayable: data.CURRENTAP,
    inventoryRetail: data.INVENTORYRETAIL,
    inventoryCost: data.INVENTORYCOST,
    openPOs: data.OPENPOS,
    pctChangeMTD: data.PCTCHANGEMTD,
    pctChangeYTD: data.PCTCHANGEYTD,
    myPctChangeMTD: data.MYPCTCHANGEMTD,
    myPctChangeYTD: data.MYPCTCHANGEYTD,
})
