import { getHeaderHeight, getHeaderTopPadding } from '../../utils/appDimensions';
import { updateObject } from '../utility'
import { getFooterHeight, getFooterBottomPadding, getFooterTopPadding } from '../../utils/appDimensions';

/*****************************************************************************/
// Module data

const SET_HEADER_DIMENSIONS = '/header/SET_HEADER_DIMENSIONS'
const SET_FOOTER_DIMENSIONS = '/header/SET_FOOTER_HEIGHT'
const SET_HEADER_COMPONENTS = '/header/SET_HEADER_COMPONENTS'

const initialState = {
    headerComponents: {
        title: null,
        left: null,
        right: null
    },
    header: {
        height: "",
        topPadding: ""
    },
    footer: {
        height: "",
        bottomPadding: "",
        topPadding: ""
    }
}

/*****************************************************************************/
// Action Creators

export const setHeaderComponents = (components) => {
    return(dispatch, getState) => {
        dispatch({
            type: SET_HEADER_COMPONENTS,
            payload: {
                headerComponents: components
            }
        })
    }
}

export const setHeaderDimensions = (dimensions) => {
    return(dispatch, getState) => {
        dispatch({
            type: SET_HEADER_DIMENSIONS,
            payload: {
                header: dimensions
            }
        })
    }
}

export const handleOrientationChange = () => {
    return(dispatch, getState) => { 
        if(window.orientation === 0 || window.orientation === 180){
            // alert("CHANGING TO PORTRAIT")
            dispatch(setHeaderDimensions({
                height: getHeaderHeight(),
                topPadding: getHeaderTopPadding()
            }))
        }
        else{
            // alert("CHANGING TO LANDSCAPE")
            dispatch(setHeaderDimensions({
                height: getHeaderHeight(),
                topPadding: getHeaderTopPadding()
            }))
        }

        dispatch(setFooterDimensions({
            height: getFooterHeight(),
            bottomPadding: getFooterBottomPadding(),
            topPadding: getFooterTopPadding()
        }))
    }
}

export const setFooterDimensions = (dimensions) => {
    return(dispatch, getState) => {
        dispatch({
            type: SET_FOOTER_DIMENSIONS,
            payload: {
                footer: dimensions
            }
        })
    }
}

/*****************************************************************************/
// Reducer

export default function dimensionsReducer(state = initialState, action) {
    switch (action.type) {
        case SET_HEADER_DIMENSIONS: return updateState(state, action)
        case SET_FOOTER_DIMENSIONS: return updateState(state, action)
        case SET_HEADER_COMPONENTS: return updateState(state, action)
        default: return state
    }
}

const updateState = (state, action) => {
    return updateObject(state, { ...action.payload })
}