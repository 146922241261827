import React from 'react'
import {
    CartIcon, 
    CartFilledIcon,
    SettingsFilledIcon,
    PaymentsIcon,
    HomeIcon,
    ItemsIcon
} from '../../icons'
import AppBar from '@mui/material/AppBar'
import Tabs from "@mui/material/Tabs"
import Tab from '@mui/material/Tab'
import { connect } from 'react-redux'
import { setTab, TABBAR_TAB } from '../TabBar/tabBarReducer'
import { setHeaderComponents } from '../../../redux/reducers/dimensionsReducer'
import { pathPush, pathGoBack, pathReplace } from '../../../redux/reducers/pathReducer'
import { loadFromLocalStorage } from '../../../utils/localStorage'
import { logActivity } from '../../../redux/reducers/accountsReducer'
import { LOG_TYPES } from '../../../logging/logTypes'

const TabBar = (props) => {
    
    //List Props
    const {
        itemCount,
        cart,
        tab,
        disabledDashboard,
        disabledItems,
        disabledCart,
        disabledPayments,
        disabledSettings,
        footer,
        setTab,
        setHeaderComponents,
    } = props

    const [retailUser, setRetailUser] = React.useState(0)

    React.useEffect(() => {
        setRetailUser(Number(loadFromLocalStorage('isRetailUser')))
    }, [])

    const dashboardLabel = () => (
        // props.isRetailUser
        retailUser
            ? 'Store'
            : 'Dashboard'
    )

    const cartLabel = () => (itemCount > 0
        ? 'Cart (' + itemCount + ')'
        : 'Cart'
    )

    const cartIcon = () => (cart.details?.length > 0
        ? <CartFilledIcon />
        : <CartIcon />
    )

    const tabs = { ...TABBAR_TAB, current: tab }

    const handleChange = (event, newTab) => {
        const tabNames = [
            'Dashboard',
            'Items',
            'Cart',
            'UniformPay Payments',
            'Settings'
        ]

        setHeaderComponents({
            title: tabNames[newTab],
            left: null,
            right: null
        })

        props.logActivity(LOG_TYPES.tab, 'Accessing tab: ' + tabNames[newTab], true)


        setTab(newTab, props.pathDirector)
    }

    const PWATabs = () => {
        var tabsConfig = [
            {
                tab: tabs.dashboard,
                label: dashboardLabel(),
                disabledTab: disabledDashboard,
                icon: <HomeIcon/>
            },
            {
                tab: tabs.items,
                label: "Items",
                disabledTab: disabledItems,
                icon: <ItemsIcon/>
            },
            {
                tab: tabs.cart,
                label: cartLabel(),
                disabledTab: disabledCart,
                icon: cartIcon()
            },
            {
                tab: tabs.payments,
                label: "Payments",
                disabledTab: disabledPayments,
                icon: <PaymentsIcon/>
            },
            {
                tab: tabs.settings,
                label: "Settings",
                disabledTab: disabledSettings,
                icon: <SettingsFilledIcon/>
            },
        ]

        const Label = ({text, variable}) => {
            return(
                <span 
                    style={{
                        color: variable ? "#D1D1D1" : "white",
                        fontSize: "11px", 
                        fontFamily: "Open Sans",
                        whiteSpace: 'nowrap'
                    }}
                >
                    {text}
                </span>
            )
        }
    
        const Icon = ({icon, variable}) => {
            return(
                <div 
                    style={{
                        color: variable ? "#D1D1D1" : "white", 
                        margin: 0,
                    }}
                >
                    {icon}
                </div>
            )
        }

        return(
            <Tabs
                value={tabs.current}
                onChange={handleChange}
                variant='fullWidth'
                sx={{'.MuiTabs-indicator' : {display: 'none'}}}
            >
                {tabsConfig.map((tab, index)=>{
                    return(
                        <Tab key={index}

                            //Tab Title
                            label={<Label text={tab.label} variable={tab.disabledTab}/>}

                            //Tab Value
                            value={tab.tab}

                            //Set tab icon
                            icon={<Icon icon={tab.icon} variable={tab.disabledTab}/>}

                            //Tab Styling
                            sx={{
                                fontSize: '0.7em',
                                paddingBottom: footer.bottomPadding,
                                paddingTop: footer.topPadding,
                                lineHeight: '10px !important',
                                backgroundColor: tabs.current === tab.tab ? '#289FD8' : (tab.disabledTab ? "#106FA2":" #1789BD"),
                                color: 'white',
                                minWidth: '20vw',
                                display: 'flex',
                                justifyContent: "flex-end",
                                alignItems: 'center',
                                height: '100%',
                            }}

                            //Is the tab disabled
                            disabled={tab.disabledTab}
                        />
                    )
                })}
            </Tabs>
        )
       
    }

    return (
        <AppBar id="dds-appbar" postition='fixed' sx={{ boxShadow: 'none', margin: '0', top: 'auto', bottom: 0}}>
            <PWATabs/>
        </AppBar>
    )
}


const mapStateToProps = state => ({
    tab: state.tabBarReducer.tab,
    disabledDashboard: state.tabBarReducer.disabledDashboard,
    disabledItems: state.tabBarReducer.disabledItems,
    disabledCart: state.tabBarReducer.disabledCart,
    disabledPayments: state.tabBarReducer.disabledPayments,
    disabledSettings: state.tabBarReducer.disabledSettings,
    cart: state.cartReducer.cart,
    itemCount: state.cartReducer.itemCount,
    footer: state.dimensionsReducer.footer,
})

const mapDispatchToProps = dispatch => ({
    setTab: (newTab, pathDirector) => dispatch(setTab(newTab, pathDirector)),
    pathDirector: {
        push: path => dispatch(pathPush(path)),
        goBack: () => dispatch(pathGoBack()),
        replace: path => dispatch(pathReplace(path)),
    },
    setHeaderComponents: (components) => dispatch(setHeaderComponents(components)),
    logActivity: (type, message, success) => dispatch(logActivity(type, message, success))

})

export default connect(mapStateToProps, mapDispatchToProps)(TabBar)