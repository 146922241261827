import { combineReducers } from 'redux'
import accountsReducer from './accountsReducer'
import cartReducer from '../../components/Tabs/CartTab/cartReducer'
import dashboardReducer from '../../components/Tabs/DashboardTab/dashboardReducer'
import itemReducer from "../../components/Tabs/ItemsTab/Item/itemReducer"
import loginReducer from './loginReducer'
import pathReducer from './pathReducer'
import preferencesReducer from '../../components/Tabs/SettingsTab/Preferences/preferencesReducer'
import storesReducer from './storesReducer'
import tabBarReducer from '../../components/Tabs/TabBar/tabBarReducer'
import toastReducer from '../../components/Toast/toastReducer'
import pwaReducer from './pwaReducer'
import tooltipReducer from './tooltipReducer'
import paymentsReducer from './paymentsReducer'
import dimensionsReducer from './dimensionsReducer'
import scannerReducer from './scannerReducer'

export default combineReducers({
    accountsReducer,
    cartReducer,
    dashboardReducer,
    itemReducer,
    loginReducer,
    pathReducer,
    preferencesReducer,
    storesReducer,
    tabBarReducer,
    toastReducer,
    pwaReducer,
    tooltipReducer,
    paymentsReducer,
    dimensionsReducer,
    scannerReducer
})
