import tryParseJSON from './tryParseJSON'

export const saveToLocalStorage = (keyname, data) => {
    if (typeof data === 'string' || data instanceof String) {
        window.localStorage.setItem(keyname, data)
    } else {
        window.localStorage.setItem(keyname, JSON.stringify(data))
    }
}

export const loadFromLocalStorage = (keyname) => {
    let loadedData = window.localStorage.getItem(keyname)

    if (loadedData) {
        const convertedData = tryParseJSON(loadedData.toString())

        if (convertedData) {
            loadedData = convertedData
        }
    }
    return loadedData
}
