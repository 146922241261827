export const LOG_TYPES = {
    error: "error",
    cart: "cart",
    item: "item",
    payment: "payment",
    account: "account",
    login: "login",
    installation: 'installation',
    dashboard: 'dashboard',
    tab: 'tab',
    settings: 'settings',
    scan: 'scan'
}