import { Preferences } from '../../../../classes/Preferences'
import { updateObject } from '../../../../redux/utility'
import { loadFromLocalStorage, saveToLocalStorage } from '../../../../utils/localStorage'

/*****************************************************************************/
// Module Data

/*
    MANAGE PREFERENCES
    -   Load Preferences
    -   Update Preferences
*/

const initialState = {
    preferences: new Preferences(),
    error: '',
}

const LOAD_PREFERENCES_SUCCESS = '/preferences/LOAD_PREFERENCES_SUCCESS'
const UPDATE_PREFERENCES_SUCCESS = '/preferences/UPDATE_PREFERENCES_SUCCESS'

/*****************************************************************************/
// loadPreferences Action Creators

function loadPreferencesSuccess(preferences) {
    return {
        type: LOAD_PREFERENCES_SUCCESS,
        payload: preferences,
    }
}

//Load the preferences from the local storage
export const loadPreferences = () => {
    return function (dispatch) {
        var preferences = loadFromLocalStorage('preferences')

        // preferences are not yet in local storage
        if (preferences === null) {
            preferences = new Preferences()
            saveToLocalStorage('preferences', preferences)
        }

        //Check to see if any preferences are missing (after an update)
        if(preferences.showUserSales === undefined){preferences.showUserSales = true}
        if(preferences.showInventory === undefined){preferences.showInventory = true}
        if(preferences.showPOs === undefined){preferences.showPOs = true}
        if(preferences.showAR === undefined){preferences.showAR = true}
        if(preferences.showAP === undefined){preferences.showAP = true}
        if(preferences.showPct === undefined){preferences.showPct = true}
        if(preferences.showRefreshButton === undefined){preferences.showRefreshButton = true}
        if(preferences.vibrateOnScan === undefined){preferences.vibrateOnScan = true}
        if(preferences.playSoundOnScan === undefined){preferences.playSoundOnScan = true}
        if(preferences.keepLoggedIn === undefined){preferences.keepLoggedIn = true}
        if(preferences.cartMode === undefined){preferences.cartMode = true}
        if(preferences.darkMode === undefined){preferences.darkMode = true}
        if(preferences.dashboardModernView === undefined){preferences.dashboardModernView = true}
        if(preferences.betaFeatures === undefined){preferences.betaFeatures = false}

        saveToLocalStorage('preferences', preferences)
        dispatch(loadPreferencesSuccess(preferences))
    }
}

/*****************************************************************************/
// updatePreferences Action Creators

export const updatePreferences = (preferences) => {
    return(dispatch, getState)=>{

        //Update betaFeatures toggle date
        var prevBetaFeatures = getState().preferencesReducer.preferences.betaFeatures

        if(prevBetaFeatures !== true && preferences.betaFeatures === true){
            preferences.betaFeaturesToggleDate = Date.now()
        }

        if(prevBetaFeatures === true && preferences.betaFeatures !== true){
            preferences.betaFeaturesToggleDate = null
        }

        saveToLocalStorage('preferences', preferences)
        dispatch( {
            type: UPDATE_PREFERENCES_SUCCESS,
            payload: preferences,
        })
    }
}

/*****************************************************************************/
// Reducer Functions

const preferencesReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_PREFERENCES_SUCCESS:
            return reduceLoadPreferencesSuccess(state, action)
        case UPDATE_PREFERENCES_SUCCESS:
            return reduceUpdatePreferencesSuccess(state, action)
        default:
            return state
    }
}

// ---------- LoadPreferences
const reduceLoadPreferencesSuccess = (state, action) => {
    return updateObject(state, { preferences: action.payload })
}

// ---------- UpdatePreferences
const reduceUpdatePreferencesSuccess = (state, action) => {
    return updateObject(state, { preferences: action.payload })
}

/*****************************************************************************/
export default preferencesReducer
