import { createTheme, responsiveFontSizes } from '@mui/material/styles'

let darkTheme = createTheme({
    palette: {
        mode: "dark",
        overrides: {
            MuiInput: {
                input: {
                    "&::placeholder": {
                        fontStyle: 'italic'
                    },
                }
            },
        },
        primary: {
            // main: "#307ABC"
            // main: "#215583"
            main: "#289FD8"

        },
        secondary: {
            main: "#106FA2"
        }
    }, 
    typography: {
        fontFamily: [
            // "Segoe UI"
            // "Poppins"
            "Open Sans"   
            // "Comic Sans"
            // 'Helvetica'
        ],
        // fontSize: 12
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#303030'
                }
            }
        }
    }
 
});

darkTheme = responsiveFontSizes(darkTheme)

export default darkTheme