import React from 'react'
import { connect } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, useTheme, useMediaQuery, Checkbox, FormControlLabel  } from "@mui/material"
import OkDialogButton from "../../../../../hoc/Buttons/OkDialogButton"
import CancelDialogButton from '../../../../../hoc/Buttons/CancelDialogButton'
import { hideAndroidPWADialog, installedPWA, blockPWA, promptForInstall } from '../../../../../redux/reducers/pwaReducer'
import GetAppIcon from '@mui/icons-material/GetApp';
import {isChrome, isDesktop} from 'react-device-detect';
import DialogHeader from '../../../../../hoc/DialogHeader/DialogHeader'
const InstallAndroidDialog = (props) => {
    
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'))
    const [checked, setChecked] = React.useState(false)
    const [standAlone, setStandAlone] = React.useState(false)

    const handleBlock = () => {
        props.blockPWA()
        props.hideAndroidPWADialog()
    }

    const handleClose = () => {
        props.hideAndroidPWADialog()
        if(checked === true){
            handleBlock()
        }
    }
    
    const handleChange = e => {
        setChecked(e.target.checked)
    }

    const handleInstall = () => {
        props.promptForInstall()

        // handleClose()
    }

    const CreateMessage = () => {

        const appName = "The Uniform Solution"
        let prompt;
        let dest;

        switch(isChrome){
            case true:
                prompt = "Would you like to install ";
                dest = "?";
                break;
            case false:
                prompt = "Would you like to add a bookmark for "
                dest = isDesktop ? " to your desktop?" : " to your Home Screen?"
                break;
            default: 
                break;
        } 

        return(
            <Typography variant={mobile ? "body1" : "h6"}>
                {prompt} <b>{appName}</b> {dest}
            </Typography>
        ) 
    }

    React.useEffect( () => {
        //show different message
        setStandAlone(window.matchMedia('(display-mode: standalone)').matches)
        
    }, [props.installable])

    const InstallContent = () => {
        return(
            <React.Fragment>
                <div style={{display: "flex", flexDirection: 'column', justifyContent: "center", padding: "1em", alignItems: 'center'}}>
                    <CreateMessage/>
                    <GetAppIcon style={{fontSize: mobile ? "5em" : "10em", color: '#4caf50'}}/>
                    <img alt='Logo' src='../../../../images/favicon.ico' style={{borderRadius: "0.5em", width: mobile ? '5em' : '10em', marginBottom: '3em'}}/>
                </div>
                
                <FormControlLabel
                    control={ <Checkbox checked={checked} onChange={handleChange} name="doNotShow" color="primary"/> }
                    label={<Typography variant="body2">Do Not Show This Message Again</Typography>}
                    style={{ position: 'absolute', bottom: 0, right: 0, margin: '1em'}}
                />

            </React.Fragment>
        )
    }

    const InstalledContent = () => {
        if(standAlone){
            handleClose()
        }
        return(
            <div>The Uniform Solution PWA has been installed to your device. Please close this browser and launch the installed application for the best experience.</div>
        )
    }

    return(
        <Dialog open={props.open && !props.isBlockPWA} onClose={handleClose} style={{position: "absolute", zIndex: 4000}}>
            <DialogTitle> <DialogHeader title={props.installable ? "Install the App" : "App Installed!"} onClick={handleClose}/></DialogTitle>
            <DialogContent dividers style={{height: '100%', position: 'relative', minWidth: "275px"}}>
                {props.installable ? 
                    <InstallContent/> : 
                    <InstalledContent/>
                }
            </DialogContent>
            <DialogActions>
                {props.installable && <OkDialogButton onClick={handleInstall}>Install</OkDialogButton>}
                {props.installable && <CancelDialogButton onClick={handleClose}/>}
            </DialogActions>
        </Dialog>
    )
}

const mapStateToProps = (state) => {
    return {
        open: state.pwaReducer.install_android_open,
        install_event: state.pwaReducer.install_event,
        isBlockPWA: state.pwaReducer.isBlockPWA,
        installable: state.pwaReducer.installable
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        hideAndroidPWADialog: () => dispatch(hideAndroidPWADialog()),
        installedPWA: () => dispatch(installedPWA()),
        blockPWA: () => dispatch(blockPWA()),
        promptForInstall: () => dispatch(promptForInstall())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallAndroidDialog)