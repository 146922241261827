import { updateObject } from '../../redux/utility'

/*****************************************************************************/
// Module data

const TOAST_SHOW = '/toast/TOAST_SHOW'
const TOAST_HIDE = '/toast/TOAST_HIDE'

export const TOAST_SEVERITY = Object.freeze({
    error: 'error',
    warning: 'warning',
    info: 'info',
    success: 'success',
})

const TOAST_DEFAULT_SEVERITY = TOAST_SEVERITY.info
const TOAST_DEFAULT_DURATION = 3000

const initialState = {
    open: false,
    message: '',
    severity: TOAST_DEFAULT_SEVERITY,
    duration: TOAST_DEFAULT_DURATION,
}

/*****************************************************************************/
// Action Creators

export const showToast = (message, severity, duration) => ({
    type: TOAST_SHOW,
    payload: {
        open: true,
        message: message || '',
        severity: severity || TOAST_DEFAULT_SEVERITY,
        duration: duration || TOAST_DEFAULT_DURATION
    }
})

export const hideToast = () => ({
    type: TOAST_HIDE,
    payload: { open: false }
})

/*****************************************************************************/
// Reducer

export default function toastReducer(state = initialState, action) {
    switch (action.type) {
        case TOAST_SHOW: return reduceShowToast(state, action)
        case TOAST_HIDE: return reduceHideToast(state, action)
        default: return state
    }
}

const reduceShowToast = (state, action) => {
    return updateObject(state, action.payload)
}

const reduceHideToast = (state, action) => {
    return updateObject(state, action.payload)
}

