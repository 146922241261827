// CartDetail represents a single line item in the cart. 
// Note that Quantity is a simple integer, while item is an InventoryItem object.
// That's why the flatten method (below) is sometimes used to return this as a simpler object

export class CartDetail {
    constructor(item, quantity) {
        this.item = item
        this.quantity = quantity
    }

    setQuantity(quantity) {
        this.quantity = quantity
    }

    flatten() {
        return {
            //this.item.etc
            itemTitle: this.item.itemTitle,
            color: this.item.color,
            size: this.item.size,
            priceFormatted: this.item.priceFormatted,
            codeDescription: this.item.codeDescription,
            description: this.item.description,
            colorHtmlPhrase: this.item.colorHtmlPhrase,
            sizeHtmlPhrase: this.item.sizeHtmlPhrase,

            //this.etc
            quantity: this.quantity
        }
    }
}

export default CartDetail