import apiStore from './apiStore'
import sessionIdParm from './sessionIdParm'
import log from './log'

export const apiVStockGet = (hostName, session, id, doneCallback, failCallback) => {

    const url = hostName 
        + "/apiVStockGet?bc=" + id 
        + "&apiToken=" + session.apiToken 
        + sessionIdParm(session);

    log(url)

    apiStore({
        url: url,
        method: 'get',
        timeout: 15000
    })
        .then(response => {
            doneCallback(response.data)
        })
        .catch(error => {
            log(error)
            failCallback(error)
        })
}

export default apiVStockGet