// import axios from 'axios'
import { updateObject } from '../utility'
import { loadFromLocalStorage, saveToLocalStorage } from '../../utils/localStorage'
import { loginDisconnect, connectToServer, disableTabs, loginAnotherAccount } from './loginReducer'
import { getSelectedAccount } from '../../components/Tabs/SettingsTab/Accounts/getSelectedAccount'
import { shallowEqual } from '../../utils/shallowEqual'
import { createCart, switchCart, deleteCart, setCart } from '../../components/Tabs/CartTab/cartReducer'
import log from '../../utils/log'
// var CryptoJS = require('crypto-js')
import AES from 'crypto-js/aes'
import enc from 'crypto-js/enc-utf8'
import { LOG_TYPES } from '../../logging/logTypes'

/*****************************************************************************/
// Module Data

const initialState = {
	accounts: [],
	selectedAccount: '',
	error: '',
	editingAccount: null,
}

const LOAD_ACCOUNTS_SUCCESS = '/account/LOAD_ACCOUNTS_SUCCESS'
const UPDATE_SELECTED_ACCOUNT = '/account/UPDATE_SELECTED_ACCOUNT'
const CREATE_ACCOUNT_SUCCESS = '/account/CREATE_ACCOUNT_SUCCESS'
const DELETE_ACCOUNT_SUCCESS = '/account/DELETE_ACCOUNT_SUCCESS'
const SET_EDIT_ACCOUNT = '/account/SET_EDIT_ACCOUNT'
const EDIT_ACCOUNT_SUCCESS = '/account/EDIT_ACCOUNT_SUCCESS'

/*****************************************************************************/
// loadAccounts Action Creators

const loadAccountsSuccess = (accounts) => ({
	type: LOAD_ACCOUNTS_SUCCESS,
	payload: accounts,
})

export const loadAccounts = () => {
	return (dispatch) => {
		log('%c-----Loading accounts-----', 'color: #757CF0; font-size: 14px;')
		var accounts
		var selectedAccount

		try {
			// accounts present in local storage
			accounts = loadFromLocalStorage('accounts')

			// decrypt accounts
			accounts = decryptAccounts(accounts)

			var processedAccounts = []
			if (accounts.length > 0) {
				// See if any accounts still have a prefix in their hostname
				for (var i = 0; i < accounts.length; i++) {
					var account = accounts[i]
					if (account.hostName.includes('https://')) {
						account.hostName = account.hostName.replace('https://', '')
					} else if (account.hostName.includes('http://')) {
						account.hostName = account.hostName.replace('http://', '')
					}
					processedAccounts.push(account)
				}
				accounts = processedAccounts
			}

			selectedAccount = loadFromLocalStorage('selectedAccount')
			if (selectedAccount === 'null') {
				selectedAccount = null
			}
		} catch {
			// accounts not present in local storage!
			log('No accounts present in local storage.')
			accounts = []
			selectedAccount = ''
		}

		log('Loaded accounts: ', accounts)

		//If there is one account loaded, have that be the selectedAccount
		if (accounts.length === 1) {
			selectedAccount = accounts[0].key
			dispatch(updateSelectedAccount(accounts[0].key))
		}

		dispatch(loadAccountsSuccess([accounts, selectedAccount]))
		log('%c-----Stop Loading accounts-----', 'color: #757CF0; font-size: 14px;')
	}
}

/*****************************************************************************/
// updateSelectedAccount Action Creators

export const selectAccountFromList = (accountKey, pathDirector) => {
	return (dispatch, getState) => {
		dispatch(updateSelectedAccount(accountKey))
		const selectedAccount = getSelectedAccount(getState().accountsReducer)
		if (selectedAccount.isGuest) {
			dispatch(loginAnotherAccount())
			dispatch(disableTabs())
			// dispatch(setCart({}))
			// dispatch(loginDisconnect())
			dispatch(connectToServer(pathDirector))
		} else {
			dispatch(loginAnotherAccount())
			dispatch(switchCart(accountKey))
			dispatch(connectToServer(pathDirector))
		}
	}
}

export const updateSelectedAccount = (accountKey) => {
	saveToLocalStorage('selectedAccount', accountKey)
	return {
		type: UPDATE_SELECTED_ACCOUNT,
		accountKey: accountKey,
	}
}

/*****************************************************************************/
// createAccount Action Creators

const createAccountSuccess = (account) => ({
	type: CREATE_ACCOUNT_SUCCESS,
	payload: account,
})

export const createAccount = (account) => {
	return (dispatch, getState) => {
		log('%cCreating account:', 'color: aqua;')
		log(account)

		if (account.isGuest) {
			log('Creating a guest account.')
			dispatch(createAccountSuccess(account))
			dispatch(updateSelectedAccount(account.key))
			dispatch(disableTabs())
			dispatch(loginDisconnect())
			encryptAccounts(Object.values({ ...getState().accountsReducer.accounts }))
		} else {
			log('Creating a normal account.')

			//Remove any prefixes and trailing forward slashes
			account.hostName = account.hostName.replace('https://', '')
			account.hostName = account.hostName.replace('http://', '')

			while (account.hostName[account.hostName.length - 1] === '/') {
				account.hostName = account.hostName.slice(0, -1)
			}

			// Success
			dispatch(createAccountSuccess(account))
			dispatch(createCart(account.key))

			// Update selected account
			dispatch(updateSelectedAccount(account.key))

			// encrypt accounts
			encryptAccounts(Object.values({ ...getState().accountsReducer.accounts }))

			dispatch(logActivity(LOG_TYPES.account, 'Created a new account.', true))

			// Connect to server
			dispatch(connectToServer())
		}
	}
}

/*****************************************************************************/
// deleteAccount Action Creators

const deleteAccountSuccess = (accountKey) => ({
	type: DELETE_ACCOUNT_SUCCESS,
	accountKey: accountKey,
})

export const deleteAccount = (accountKey) => {
	return (dispatch, getState) => {
		dispatch(deleteAccountSuccess(accountKey))
		encryptAccounts(Object.values({ ...getState().accountsReducer.accounts }))
		dispatch(deleteCart(accountKey))
		const selectedAccount = getState().accountsReducer.selectedAccount
		if (accountKey === selectedAccount) {
			dispatch(updateSelectedAccount(''))
			dispatch(loginDisconnect())
		}
		dispatch(logActivity(LOG_TYPES.account, 'Deleted an account.', true))
	}
}

/*****************************************************************************/
// editAccount Action Creators

export const setEditAccount = (account) => ({
	type: SET_EDIT_ACCOUNT,
	payload: account,
})

const editAccountSuccess = (account) => ({
	type: EDIT_ACCOUNT_SUCCESS,
	payload: account,
})

export const editAccount = (account, requireReconnect) => {
	return (dispatch, getState) => {
		const selectedAccount = getSelectedAccount(getState().accountsReducer)
		var connect
		if (requireReconnect === undefined) {
			connect = true
		} else {
			connect = requireReconnect
		}

		//Success
		dispatch(editAccountSuccess(account))

		// encrypt accounts
		encryptAccounts(Object.values({ ...getState().accountsReducer.accounts }))

		//Check if something actually changed, that would cause a connect.
		if (selectedAccount.key === account.key) {
			if (!shallowEqual(selectedAccount, account)) {
				if (connect) {
					dispatch(connectToServer())
				}
			}
		}
	}
}

export const checkOfflineMid = () => {
	return (dispatch, getState) => {
		try {
			const account = getSelectedAccount(getState().accountsReducer)
			var expiryDate = account.offlineMid.expiryDate

			if (!expiryDate || expiryDate.length === 0) {
				return
			}

			//See if it is expired
			var today = new Date()
			var diff = Date.parse(today) - Date.parse(expiryDate)
			var dayLength = 223396000 //24 hours in milliseconds

			if (diff > dayLength) {
				var newAccount = { ...account }
				newAccount.offlineMid = { temporaryDeviceId: '', mid: '', dateCreated: '', displayName: '' }
				dispatch(editAccount(newAccount))
				return
			}
		} catch {
			//There is no expiry date.
			return
		}
	}
}

export const logActivity = (type, message, success) => {
	return (dispatch, getState) => {
		// console.log('logActivity')
	}
}

/*****************************************************************************/
// Reducer Functions

const accountsReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOAD_ACCOUNTS_SUCCESS:
			return reduceLoadAccountsSuccess(state, action)
		case UPDATE_SELECTED_ACCOUNT:
			return reduceUpdateSelectedAccount(state, action)
		case CREATE_ACCOUNT_SUCCESS:
			return reduceCreateAccountSuccess(state, action)
		case DELETE_ACCOUNT_SUCCESS:
			return reduceDeleteAccountSuccess(state, action)
		case EDIT_ACCOUNT_SUCCESS:
			return reduceEditAccountSuccess(state, action)
		case SET_EDIT_ACCOUNT:
			return reduceSetEditAccount(state, action)
		default:
			return state
	}
}

// ---------- LoadAccounts

const reduceLoadAccountsSuccess = (state, action) => {
	return updateObject(state, { accounts: action.payload[0], selectedAccount: action.payload[1] })
}

// ---------- UpdateSelectedAccount

const reduceUpdateSelectedAccount = (state, action) => {
	return updateObject(state, { selectedAccount: action.accountKey })
}

// ---------- CreateAccount

const reduceCreateAccountSuccess = (state, action) => {
	return updateObject(state, { accounts: [...state.accounts, action.payload] })
}

// ---------- DeleteAccount

const reduceDeleteAccountSuccess = (state, action) => {
	return updateObject(state, { accounts: state.accounts.filter((account) => account.key !== action.accountKey) })
}

// ---------- EditAccount

const reduceEditAccountSuccess = (state, action) => {
	const index = state.accounts.findIndex((account) => account.key === action.payload.key)
	const newArray = [...state.accounts]
	newArray[index] = action.payload
	return updateObject(state, { accounts: newArray })
}

// ---------- Set Edit Account
const reduceSetEditAccount = (state, action) => {
	return updateObject(state, { editingAccount: action.payload })
}

/*****************************************************************************/
// Encryption Functions

const encryptAccounts = (accounts) => {
	var encryptedAccounts = []

	for (var i = 0; i < accounts.length; i++) {
		// var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(accounts[i]), 'DDSENCRYPT').toString()
		var ciphertext = AES.encrypt(JSON.stringify(accounts[i]), 'DDSENCRYPT').toString()

		encryptedAccounts.push(ciphertext)
	}
	saveToLocalStorage('accounts', JSON.stringify(encryptedAccounts))
}

const decryptAccounts = () => {
	var accounts = loadFromLocalStorage('accounts')

	var decryptedAccounts = []

	for (var i = 0; i < accounts.length; i++) {
		// var bytes = CryptoJS.AES.decrypt(accounts[i], 'DDSENCRYPT')
		var bytes = AES.decrypt(accounts[i], 'DDSENCRYPT')
		// var decryptedAccount = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
		var decryptedAccount = JSON.parse(bytes.toString(enc))
		decryptedAccounts.push(decryptedAccount)
	}
	return decryptedAccounts
}

/*****************************************************************************/
export default accountsReducer
