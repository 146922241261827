import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material"
import { getFullScreenDialogPadding } from '../../utils/appDimensions';
import SubHeaderText from '../Typography/SubHeaderText'

const DialogHeader = ({title, onClick, extraPadding}) => {
    return (
        <div id="DialogHeader" style={{display: 'flex', justifyContent: 'space-between', marginTop: extraPadding ? getFullScreenDialogPadding() : ""}}>
            <div style={{maxWidth: "80%"}}>
                <SubHeaderText>{title}</SubHeaderText>
            </div>
            <div>
                <IconButton style={{color: '#106FA2'}} size={"small"} onClick={onClick}>
                    <CloseIcon size={"small"} style={{fontSize: "20px"}}/>
                </IconButton>
            </div>
        </div>
    );
};

export default DialogHeader;