import { createTheme, responsiveFontSizes } from '@mui/material/styles'

let lightTheme = createTheme({
    overrides: {
        MuiInput: {
            input: {
                "&::placeholder": {
                    fontStyle: 'italic'
                },
            }
        }
    },
    palette: {
        type: "light",
        primary: {
            // main: "#307ABC"
            // main: "#106FA2"
            main: "#289FD8"
        },
        secondary: {
            // main: "#289FD8"
            // main: "#106FA2"
            main: "#0EC99F"
        }
    },
    typography: {
        fontFamily: [
            // "Segoe UI"
            // "Poppins"
            "Open Sans"   
            // "Comic Sans"
            // 'Helvetica'
        ],
        // fontSize: 12
    }
});

lightTheme = responsiveFontSizes(lightTheme)

export default lightTheme