import { updateObject } from '../utility'
import { showToast } from '../../components/Toast/toastReducer'
import { loadFromLocalStorage, saveToLocalStorage } from '../../utils/localStorage'
import { pathPush, PATHS } from './pathReducer'
import upAuthKeyPayment from '../../utils/upAuthKeyPayment'
import apiUpSendEmail from '../../utils/apiUpSendEmail'
import { createAccount, editAccount, logActivity } from '../reducers/accountsReducer'
import { TABBAR_TAB, setTab } from '../../components/Tabs/TabBar/tabBarReducer'
import Account from '../../classes/Account'
import { getSelectedAccount } from '../../components/Tabs/SettingsTab/Accounts/getSelectedAccount'
import upReportTransactions from '../../utils/upReportTransactions'
import upReverseTransaction from '../../utils/upReverseTransaction'
import apiUpConfirmPaymentUnlock from '../../utils/apiUpConfirmPaymentUnlock'
import log from '../../utils/log'
import { isNullOrUndefined } from 'util'
import { fullSteamHostedPaymentsUrl, fullSteamHostedPaymentsUrl_Test } from '../../utils/upUrl';
import { LOG_TYPES } from '../../logging/logTypes'

/*****************************************************************************/
// Module data

const SET_MIDS = '/payments/SET_MIDS'
const SET_ACTIVE_MID = '/payments/SET_ACTIVE_MID'
const SET_PAYMENT_STORES = '/payments/SET_PAYMENT_STORES'

const SET_MANUAL_FORM_STATE = '/payments/SET_MANUAL_FORM_STATE'
const SET_PAYMENT_LOADING = '/payments/SET_PAYMENT_LOADING'
const SET_PAYMENT_RESPONSE = '/payments/SET_PAYMENT_RESPONSE'
const SET_PENDING_PAYMENTS = '/payments/SET_PENDING_PAYMENTS'

const SET_REPORTS = '/payments/SET_REPORTS'
const SET_REPORTS_LOADING = '/payments/SET_REPORTS_LOADING'
const SET_SELECTED_REPORT = '/payments/SET_SELECTED_REPORT'

const SET_REVERSE_LOADING = '/payments/SET_REVERSE_LOADING'
const SET_PENDING_REVERSAL = '/payments/SET_PENDING_REVERSAL'

const SET_SHOW_DISCLAIMER = '/payments/SET_SHOW_DISCLAIMER'

const SET_MANUAL_PAYMENTS_STATE = '/payments/SET_MANUAL_PAYMENTS_STATE'

const initialState = {

    manualPaymentsState: {

        paymentValue: null,
        note: null,
        authKey: null,
        store: null
        
    },

    //Manual Payment
    recaptchaLoaded: false,
    manualFormState:{
        cardNumberValid: false,
        cardNumberInvalid: false,
        expirationMonthValid: false,
        expirationMonthInvalid: false,
        expirationYearValid: false,
        expirationYearInvalid: false,
        cvvValid: false,
        cvvInvalid: false,
        hostedPaymentsError: null,
        nameOnAccountValid: false,
        nameOnAccountInvalid: false,
        zipValid: false,
        zipInvalid: false
    },
    paymentLoading: false,
    transactionId: "",
    paymentResponse: null,
    pendingPayments: [],

    //MIDs
    MIDs: [],
    activeMID:  null, 
    paymentStores: [],

    //Reports
    reports: [],
    reportsLoading: false,
    selectedReport: null,

    //Reverse Transaction
    reverseLoading: false,
    pendingReversals: [],

    //Disclaimer
    showDisclaimer: true,
}

/*****************************************************************************/
// Action Creators

export const setManualPaymentsState = (data) => {
    return (dispatch, getState) => {

        var manualPaymentsState = getState().paymentsReducer.manualPaymentsState

        var newManualPaymentsState = {
            ...manualPaymentsState,
            ...data
        }

        dispatch({
            type: SET_MANUAL_PAYMENTS_STATE,
            payload: {
                manualPaymentsState: newManualPaymentsState
            }
        })
    }
}

export const getAuthKey = (data) => {
    return(dispatch, getState) => {

        function getPaymentStore(){
            var paymentStores = getState().paymentsReducer.paymentStores
            var selectedAccount = getSelectedAccount(getState().accountsReducer)


            if(selectedAccount.isGuest){
                return selectedAccount.offlinePaymentStores[0]
            }

            //Try to look in payment stores. Then look in offline Mids.
            for(var i = 0; i < paymentStores.length; i++){
                if(selectedAccount.paymentStoreNumber === paymentStores[i].StoreNumber){
                    return paymentStores[i]
                }
            }

            //See if there is an offlineMid instead
            var store = {
                MerchantAlias:  selectedAccount.offlineMid.unlockCode,
                MerchantID:selectedAccount.offlineMid.merchantIdentifier
            }

            if(isNullOrUndefined(store.MerchantAlias) || isNullOrUndefined(store.MerchantID)){
                dispatch(showToast('Unable to supply MID for Auth Token.', "error", 3000))
                return
            }else{
                return store
            }

            //return null
        }

        //Set paymentValue, note, and store.
        var newManualPaymentsState = {
            ...data,
            loading: true,
            store: getPaymentStore(), // get store from accountsReducer.
            deviceID: getState().loginReducer.deviceID // get deviceID from the loginReducer.
        }

        if(isNullOrUndefined(newManualPaymentsState.store)){
            dispatch(logActivity(LOG_TYPES.payment, 'Unable to retrieve UPay Auth Token.', false))
            dispatch(showToast('Unable to find a valid payment store. ' + getPaymentStore().StoreName, "error", 3000))
            return
        }

        //Set State, currently loading.
        dispatch(setManualPaymentsState(newManualPaymentsState))

        //Go to manual payments tab.
        dispatch(pathPush(PATHS.manualPayments))

        function onSuccess(authKey, testing){
        
            dispatch(logActivity(LOG_TYPES.payment, 'Retrieved UPay Auth Token.', true))

            function loadScript(authKey, testing, onLoadScriptSuccess, onLoadScriptFailure){
                //See if the script exists.
                const existingScript = document.getElementById("fullSteamHostedPaymentsScript")
                        
                if(!existingScript){

                    //Try to create and set the script.
                    try{    
                        var fullSteamHostedPaymentsScript = document.createElement('script')
                        fullSteamHostedPaymentsScript.id = "fullSteamHostedPaymentsScript"
                        fullSteamHostedPaymentsScript.src = testing ? fullSteamHostedPaymentsUrl_Test : fullSteamHostedPaymentsUrl
                        document.body.appendChild(fullSteamHostedPaymentsScript)
                        fullSteamHostedPaymentsScript.onload  = () => {
                            onLoadScriptSuccess(authKey)
                        }
                    }
                    catch(err){
                        onLoadScriptFailure()
                    }
                }

                //The script already exist, just load the form.
                if(existingScript){
                    onLoadScriptSuccess(authKey)
                }
            }

            function onLoadScriptSuccess(authKey){
                var manualPaymentsState = getState().paymentsReducer.manualPaymentsState
                var newManualPaymentsState = {
                    ...manualPaymentsState, 
                    loading: false,
                    authKey: authKey, 
                }
                dispatch(setManualPaymentsState(newManualPaymentsState))
                dispatch(logActivity(LOG_TYPES.payment, 'Successfully loaded UPay script.', true))

            }

            function onLoadScriptFailure(){
                var manualPaymentsState = getState().paymentsReducer.manualPaymentsState
                var newManualPaymentsState = {...manualPaymentsState, loading: false}
                dispatch(showToast('Unable to load FullSteam Payments Script.', "error", 3000))
                dispatch(logActivity(LOG_TYPES.payment, 'Cannot loaded UPay script.', false))
                setTimeout(()=>{
                    dispatch(setManualPaymentsState(newManualPaymentsState))
                    dispatch(pathPush(PATHS.newPayments))
                }, 1000)
            }

            loadScript(authKey, testing, onLoadScriptSuccess, onLoadScriptFailure )
        }

        function onFailure(){

            dispatch(logActivity(LOG_TYPES.payment, 'Could not retrieve UPay Auth Token.', false))

            var manualPaymentsState = getState().paymentsReducer.manualPaymentsState
            var newManualPaymentsState = {...manualPaymentsState, loading: false}
            
            dispatch(showToast('Unable to retrieve authentication token.', "error", 3000))
            setTimeout(()=>{
                dispatch(setManualPaymentsState(newManualPaymentsState))
                dispatch(pathPush(PATHS.newPayments))
            }, 1000)
            
        }

        //Get the auth key.
        upAuthKeyPayment(newManualPaymentsState, onSuccess, onFailure)
    }
}

export const setShowPaymentDisclaimer = (show) => {
    return(dispatch, getState) => {
        dispatch({
            type: SET_SHOW_DISCLAIMER,
            payload: {
                showDisclaimer: show
            }
        })
    }
}

export const setPaymentStores = (paymentStores) => {
    log("%cSet Payment Stores", "color: gold; font-size: 16px")
    log(paymentStores)
    return(dispatch, getState)=> {
        dispatch({
            type: SET_PAYMENT_STORES,
            payload:{
                paymentStores: paymentStores
            }
        })

        // Update offline payment stores in the selected account
        var selectedAccount = getSelectedAccount(getState().accountsReducer)

        selectedAccount.offlinePaymentStores = paymentStores
        dispatch(editAccount(selectedAccount, false))
    }
} 

export const confirmPaymentUnlock = (unlockCode, storeName, pathDirector, onSuccess, onFailure) => {
    return(dispatch, getState) => {

        let deviceID = getState().loginReducer.deviceID

        const doneCallback = (mid, expiryDate) => {
            manageLocalStorage(mid, storeName, expiryDate)
            onSuccess()
            dispatch(showToast("The Guest Account successfully added.", "success", 3000))
        }

        const failCallback = (error) => {
            onFailure(error)
        }

        const manageLocalStorage = (mid, storeName, expiryDate) => {

            //Create a guest acount.
            var guestAccount = new Account()
            guestAccount.accountName = "Guest (" + storeName + ")"
            guestAccount.isGuest = true 
            guestAccount.isPending = false
            guestAccount.offlinePaymentStores = [
                    {
                        StoreNumber: 1, 
                        StoreName: storeName,
                        MerchantID: mid,
                        MerchantAlias: unlockCode,
                        expiryDate: expiryDate
                    }
                ]

            dispatch(createAccount(guestAccount))

            // go to payments tab
            // dispatch(setTab(TABBAR_TAB.payments, pathDirector, PATHS.newPayments))
            dispatch(setTab(TABBAR_TAB.settings, pathDirector, PATHS.accounts))

        }

        //Ensure that the mid and unlock code are valid first.
        apiUpConfirmPaymentUnlock(deviceID, unlockCode, doneCallback, failCallback)
    }
}

export const updateManualFormState = (field) => {
    return(dispatch, getState) => {
        //Get current manual form state
        let manualFormState = {...getState().paymentsReducer.manualFormState}

        //Update state based on field
        switch(field){
            case "cardNumberValid":
                log("Card number is valid.")
                manualFormState.cardNumberValid = true;
                manualFormState.cardNumberInvalid = false;
                break
            case "cardNumberInvalid":
                log("Card number is invalid.")
                manualFormState.cardNumberValid = false;
                manualFormState.cardNumberInvalid = true;
                break
            case "expirationMonthValid":
                log("Expiration month is valid.")
                manualFormState.expirationMonthValid = true;
                manualFormState.expirationMonthInvalid = false;
                break
            case "expirationMonthInvalid":
                log("Expiration month is invalid.")
                manualFormState.expirationMonthValid = false;
                manualFormState.expirationMonthInvalid = true;
                break
            case "expirationYearValid":
                log("Expiration year is valid.")
                manualFormState.expirationYearValid = true;
                manualFormState.expirationYearInvalid = false;
                break
            case "expirationYearInvalid":
                log("Expiration year is invalid.")
                manualFormState.expirationYearValid = false;
                manualFormState.expirationYearInvalid = true;
                break
            case "cvvValid":
                log("CVV is valid.")
                manualFormState.cvvValid = true;
                manualFormState.cvvInvalid = false;
                break
            case "cvvInvalid":
                log("CVV is invalid.")
                manualFormState.cvvValid = false;
                manualFormState.cvvInvalid = true;
                break
            case "nameOnAccountValid":
                log("Account Name is valid.")
                manualFormState.nameOnAccountValid = true;
                manualFormState.nameOnAccountInvalid = false;
                break
            case "nameOnAccountInvalid":
                log("Account Name is invalid.")
                manualFormState.nameOnAccountValid = false;
                manualFormState.nameOnAccountInvalid = true;
                break
            case "zipValid":
                log("Zip is valid.")
                manualFormState.zipValid = true;
                manualFormState.zipInvalid = false;
                break
            case "zipInvalid":
                log("Zip is invalid.")
                manualFormState.zipValid = false;
                manualFormState.zipInvalid = true;
                break
            default:
                break
        }
        dispatch({
            type: SET_MANUAL_FORM_STATE,
            payload: {
                manualFormState: manualFormState
            }
        })
    }
}

export const loadMIDs = () => {
    let MIDs = loadFromLocalStorage('MIDs') || []
    let activeMID = loadFromLocalStorage('activeMID') || null

    if(activeMID === 'null'){
        activeMID = null
    }

    return(dispatch, getState) => {
        dispatch({
            type: SET_MIDS,
            payload: {
                MIDs: MIDs
            }
        })
        dispatch({
            type: SET_ACTIVE_MID,
            payload: {
                activeMID: activeMID
            }
        })
    }
}

export const setPaymentLoading = (loading) => {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PAYMENT_LOADING,
            payload: {
                paymentLoading: loading
            }
        })
    }
}

//Finalize Payment
export const setPaymentResponse = (res) => {
    return(dispatch, getState) => {

        // var res = {
        //     store: manualPaymentsState.store,
        //     transactionID: transactionId,
        //     accountToken: accountToken,
        //     paymentResponse: JSON.parse(paymentRes)
        // }
        // setPaymentResponse([transactionId, accountToken, JSON.parse(paymentRes)])
        function getPaymentStore(){
            var paymentStores = getState().paymentsReducer.paymentStores
            var selectedAccount = getSelectedAccount(getState().accountsReducer)


            if(selectedAccount.isGuest){
                return selectedAccount.offlinePaymentStores[0]
            }

            //Try to look in payment stores. Then look in offline Mids.
            for(var i = 0; i < paymentStores.length; i++){
                if(selectedAccount.paymentStoreNumber === paymentStores[i].StoreNumber){
                    return paymentStores[i]
                }
            }

            //See if there is an offlineMid instead
            var store = {
                MerchantAlias:  selectedAccount.offlineMid.unlockCode,
                MerchantID:selectedAccount.offlineMid.merchantIdentifier
            }

            if(isNullOrUndefined(store.MerchantAlias) || isNullOrUndefined(store.MerchantID)){
                dispatch(showToast('Unable to supply MID for Auth Token.', "error", 3000))
                return
            }else{
                return store
            }

            return null
        }

        var currentdate = new Date(); 
        //Convert to object similar to response of ReportTransactions.

        var pendingPayment = {
            BusinessName: "",
            CardLast4: res.paymentResponse.gatewayResponse.accountDetails.paymentAccountLast4,
            CreateDateUtc: currentdate,
            CustomerId: res.paymentResponse.requestDetails.customerId,
            DateTime: currentdate,
            Email: "",
            MerchantID: "",
            NameOnAccount: res.paymentResponse.gatewayResponse.accountDetails.nameOnAccount,
            Source: "WebStore",
            Status: "Pending",
            TotalAmount: res.paymentResponse.gatewayResponse.amountDetails.attemptedTotalAmount, 
            tusTransId: "",
            upDateTime: currentdate,
            upTransId: res.transactionID,
            upTransIdRef: "",
            store: res.store
        }

        //Loading pendingPayments from local Storage.
        var pendingPayments = loadFromLocalStorage('PendingPayments')

        //No pending payments yet.
        if(pendingPayments === null){
            pendingPayments = []
        }

        //Add new payment to pendingPayments. And save to local storage.
        pendingPayments.push(pendingPayment)
        saveToLocalStorage('PendingPayments', pendingPayments)

        dispatch(logActivity(LOG_TYPES.payment, 'Created a payment.', true ))

        dispatch({
            type: SET_PAYMENT_RESPONSE,
            payload: {
                transactionId: res.transactionID,
                paymentResponse: res.paymentResponse,
                pendingPayments: [...getState().paymentsReducer.pendingPayments, pendingPayment]
            }
        })
    }
}

export const sendConfirmationEmail = (data) => {
    return(dispatch, getState) => {
        log("Sending confirmation email...")
        const failCallback = (error) => {
            dispatch(logActivity(LOG_TYPES.payment, 'Unable to send payment confirmation email.', false ))
            dispatch(showToast("Unable to send confirmation email.", "error", 3000))
        }

        const doneCallback = (data) => {
            dispatch(logActivity(LOG_TYPES.payment, 'Successfully sent payment confirmation email.', true ))
            dispatch(showToast("Email Sent!", "success", 3000))
        }

        var DeviceID    = getState().loginReducer.deviceID
        var MerchantAlias = ""
        var selectedAccount = getSelectedAccount(getState().accountsReducer)
        var paymentStores = getState().paymentsReducer.paymentStores
        var storeName = ""
        for(var i = 0; i < paymentStores.length; i++){
            if(selectedAccount.paymentStoreNumber === paymentStores[i].StoreNumber){
                MerchantAlias = paymentStores[i].MerchantAlias
                storeName = paymentStores[i].StoreName
            }
        }
        var Recipient   = data.EmailAddress
        var accountDetails = data.PaymentResponse.gatewayResponse.accountDetails
        var requestDetails = data.PaymentResponse.requestDetails
        var note = requestDetails.customerId
        var amountDetails = data.PaymentResponse.gatewayResponse.amountDetails
        var amount = amountDetails.approvedTotalAmount.toFixed(2)
        var transactionId = data.PaymentResponse.gatewayResponse.transactionID
        var companyName = data.CompanyName

        var Body = "USW PWA - Manual Payment Confirmation. Powered by UniformPay. \r\n\r\n"
                + "Company: " + companyName + "\r\n" 
                + "Store: " + storeName + "\r\n\r\n"
                + "Transaction ID: " + String(transactionId) + "\r\n"
                + "Recipient Name: " + accountDetails.nameOnAccount + "\r\n"
                + "Payment Amount: $" + String(amount) + "\r\n"
                + "Additional Note: " + note
        apiUpSendEmail(DeviceID, MerchantAlias, Recipient, Body, doneCallback, failCallback)
    }
}

function isExpired(createdAt){
    let oneday = 60 * 60 * 24 * 1000 
    const now = new Date()
    const originalDate = new Date(createdAt)

    if((now - originalDate) > oneday){
        return true
    }
    return false
}

export const updatePendingTransactions = (reports) => {
    return(dispatch, getState) => {

        var pendingPayments = loadFromLocalStorage('PendingPayments')

        if(pendingPayments === null){
            //No pending payments. None to remove. Return.
            return
        }

        var validDates = []
        var newArray = []

        //Loop through payments in local storage. Only grab payments < 1 day.
        for(var i= 0; i < pendingPayments.length; i++){
            if(!isExpired(pendingPayments[i].DateTime)){
                validDates.push(pendingPayments[i])
            }    
        }

        //Do not repeat reports returned from api call.
        if(reports){
            var duplicate = false

            //Loop through valid payments in local storage.
            for(i = 0; i < validDates.length; i++){
                duplicate = false
                for(var j = 0; j < reports.length; j++){

                    //If the same payment is in local storage and returned from API call...
                    if(reports[j].upTransId.toString() === validDates[i].upTransId.toString()){

                        duplicate = true
                        //update store in returned reports

                        //use the date in local storage

                        reports[j].store = validDates[i].store
                        newArray.push(reports[j])

                    }
                }
                if(!duplicate){
                    newArray.push(validDates[i])
                }
            }
        }

        //Need to save pendingPayments to state and to local storage.
        dispatch({
            type: SET_PENDING_PAYMENTS,
            payload:{
                pendingPayments: newArray
            }
        })
        saveToLocalStorage("PendingPayments", newArray)
    }
}

export const getDisplayedTransactions = (reports) => {
    return(dispatch, getState) => {
        
        //Sets the pendingPayments in the reducer.
        dispatch(updatePendingTransactions(reports))

        //Get the new pendingPayments
        var pendingPayments = getState().paymentsReducer.pendingPayments

        //Combine the lists
        // var newReports = [...reports, ...pendingPayments]
        var newReports = [...pendingPayments]

        dispatch(setReports(newReports.reverse()))
    }
}

//Reports
export const setReports = (reports) => {
    return (dispatch, getState) => {
        dispatch({
            type: SET_REPORTS,
            payload: {
                reports: reports
            }
        })
    }
}

export const LoadReports = () => {
    //Returns list of transactions within a date range. 
    //Only transactions where the deviceID is equal to the current deviceID!
    return(dispatch, getState) => {

        var selectedAccount = getSelectedAccount(getState().accountsReducer)
        const updateLoading = (loading) => {
            dispatch({
                type: SET_REPORTS_LOADING,
                payload: {
                    reportsLoading: loading
                }
            })
        }

        const doneCallback = (data) => {
            log("%c-----upReportTransactions SUCCESS-----", "color: gold;")
            dispatch(logActivity(LOG_TYPES.payment, 'Loaded payment reports.', true ))

            dispatch(getDisplayedTransactions(data.Transactions))
            updateLoading(false)
        }

        const onFailure = () => {
            log("%c-----upReportTransactions FAILURE-----", "color: red;")
            dispatch(logActivity(LOG_TYPES.payment, 'Unable to load payment reports.', false ))
            updateLoading(false)
            dispatch(showToast('Unable to retreive reports.', "error", 3000))
            dispatch(setReports([]))
        }

        updateLoading(true)

        // Get date range
        let today = new Date();
        let yesterday = today - 1000 * 60 * 60 * 24;
        yesterday = new Date(yesterday);
   
        //Get the mid
        let deviceID = getState().loginReducer.deviceID
        
        var paymentStores = getState().paymentsReducer.paymentStores
        var mid = ""
        var merchantAlias = ""
        var dateRange = {  StartDate: yesterday, EndDate: today }

        for(var i = 0; i < paymentStores.length; i++){
            if(selectedAccount.paymentStoreNumber === paymentStores[i].StoreNumber){
                mid = paymentStores[i].MerchantID
                merchantAlias = paymentStores[i].MerchantAlias
            }
        }
        upReportTransactions(deviceID, merchantAlias, mid, dateRange, doneCallback, onFailure)
    }
}

export const setSelectedReport = (report) => {
    return(dispatch, getState) => {
        dispatch({
            type: SET_SELECTED_REPORT,
            payload: {
                selectedReport: report
            }
        })
    }
}

//Reversals
export const addPendingReversal = (pendingReversal) => {
    return(dispatch, getState) => {

        //Get current pending reversals
        var pendingReversals = loadFromLocalStorage('PendingReversals')

        //No pending reversals exist yet
        if(pendingReversals === null){
            pendingReversals = []
        }

        //Add new pending reversal
        pendingReversals.push(pendingReversal)

        dispatch({
            type: SET_PENDING_REVERSAL,
            payload: {
                pendingReversals
            }
        })
        saveToLocalStorage('PendingReversals', pendingReversals)
    }
}

export const removePendingReversal = (id) => {
    return(dispatch, getState) => {
        var pendingReversals = getState().paymentsReducer.pendingReversals
        var newArray = [];

        for(var i=0; i < pendingReversals.length; i++){
            if(pendingReversals[i].transactionID.toString() !== id.toString() ){
                newArray.push(pendingReversals[i])
            }
        }

        dispatch({
            type: SET_PENDING_REVERSAL,
            payload: {
                pendingReversals: newArray
            }
        })
        saveToLocalStorage("PendingReversals", newArray)
    }
}

export const updatePendingReversals = () => {
    return(dispatch, getState) => {

        // Check pending reversals. Do not let the local storage get full of pending reversals.
        var pendingReversals = loadFromLocalStorage('PendingReversals')
        var newArray = []

        if(pendingReversals === null){
            return
        }

        for(var i = 0; i < pendingReversals.length; i++){
            if(!isExpired(pendingReversals[i].date)){
                newArray.push(pendingReversals[i])
            }
        }

        saveToLocalStorage('PendingReversals', newArray)
        dispatch({
            type: SET_PENDING_REVERSAL,
            payload: {
                pendingReversals: newArray
            }
        })
    }
}

export const reverseTransaction = (transactionID, totalAmount) => {
    return(dispatch, getState) => {
        //Get the mid
        let deviceID = getState().loginReducer.deviceID
        var selectedAccount = getSelectedAccount(getState().accountsReducer)
        var paymentStores = getState().paymentsReducer.paymentStores
        var mid = ""
        var merchantAlias = ""
        for(var i = 0; i < paymentStores.length; i++){
            if(selectedAccount.paymentStoreNumber === paymentStores[i].StoreNumber){
                mid = paymentStores[i].MerchantID
                merchantAlias = paymentStores[i].MerchantAlias
            }
        }

        const updateLoading = (loading) => {
            dispatch({
                type: SET_REVERSE_LOADING,
                payload: {
                    reverseLoading: loading
                }
            })
        }

        const doneCallback = (data) => {
            log("%c-----upReverseTransaction SUCCESS-----", "color: gold;")
            dispatch(logActivity(LOG_TYPES.payment, 'Reversed transaction.', true ))
            dispatch(showToast('Successfully reversed transaction.', "success", 3000))
            dispatch(addPendingReversal({transactionID: transactionID, date: new Date()}))
            updateLoading(false)
        }

        const failCallback = (error) => {
            log("%c-----upReverseTransaction FAILURE-----", "color: red;")
            dispatch(logActivity(LOG_TYPES.payment, 'Failed to reverse transaction.', false ))
            dispatch(showToast(error || 'Unable to reverse transaction.', "error", 3000))
            updateLoading(false)
        }

        updateLoading(true)
        upReverseTransaction(deviceID, merchantAlias, mid, transactionID, totalAmount, doneCallback, failCallback)
    }
}



/*****************************************************************************/
// Reducer

export default function paymentsReducer(state = initialState, action) {
    switch (action.type) {
        
        //Manual Payment
        case SET_MANUAL_PAYMENTS_STATE: return updateState(state, action)
        case SET_MANUAL_FORM_STATE: return updateState(state, action)
        case SET_PAYMENT_LOADING: return updateState(state, action)
        case SET_PAYMENT_RESPONSE: return updateState(state, action)
        case SET_PENDING_PAYMENTS: return updateState(state, action)

        //MIDs
        case SET_MIDS: return updateState(state, action)
        case SET_ACTIVE_MID: return updateState(state, action)
        case SET_PAYMENT_STORES: return updateState(state, action)

        //Reporting
        case SET_REPORTS: return updateState(state, action)
        case SET_REPORTS_LOADING: return updateState(state, action)
        case SET_SELECTED_REPORT: return updateState(state, action)

        //Reverse
        case SET_REVERSE_LOADING: return updateState(state, action)
        case SET_PENDING_REVERSAL: return updateState(state, action)

        //Disclaimer
        case SET_SHOW_DISCLAIMER: return updateState(state, action)
        default: return state
    }
}

const updateState = (state, action) => {
    return updateObject(state, { ...action.payload })
}