import React from 'react'
import { connect } from 'react-redux'
import { IOSShareIcon, IOSAddHomeIcon } from '../../../../icons'
import { Dialog, DialogTitle, DialogActions, DialogContent, Checkbox, FormControlLabel } from "@mui/material"
import OkDialogButton from '../../../../../hoc/Buttons/OkDialogButton'
import { blockPWA, hideIOSPWADialog } from '../../../../../redux/reducers/pwaReducer'
import { isSafari } from 'react-device-detect'
import DialogHeader from '../../../../../hoc/DialogHeader/DialogHeader'

const InstallIOSDialog = (props) => {
  const [checked, setChecked] = React.useState(false)
  const iconColor = '#2d76bb'

  const handleChange = (e) => {
    setChecked(e.target.checked)
  }

  const handleClose = (event, reason) => {
      if(checked === true){
        props.blockPWA()
      }
      props.hideIOSPWADialog()
  }

  // React.useEffect(()=>{
  //   log("Rerender InstallIOSDialog")
  // }, [props.ios_dialog_clicked])

  const lineItem = {
    display: 'flex', 
    alignItems: 'flex-end', 
    justifyContent: 'flex-start'
  }

  return (
    <Dialog open={props.open && !props.isBlockPWA} onClose={handleClose}>
      <DialogTitle>
        <DialogHeader title={"Install the App"} onClick={handleClose}/>
      </DialogTitle>
      <DialogContent dividers style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", minWidth: "275px"}}>
        <div><img alt='Logo' src='../../../../../../images/favicon.ico' width='64px' /></div>
        <div style={{ textAlign: "center"}}>
          <div style={lineItem}>To install The Uniform Solution:</div>

          {!isSafari && <div style={lineItem}>Open The Uniform Solution PWA on Safari.</div>}
          <div style={lineItem}>1. Tap <IOSShareIcon color={iconColor}/></div>
          <div style={lineItem}>2. Tap "Add to Home Screen" <IOSAddHomeIcon color={iconColor}/></div>
          <div style={lineItem}>3. Tap "Add" to confirm.</div>
        </div>
        <br/>

        {props.ios_dialog_clicked === true ?
          null :
          <FormControlLabel
              control={ <Checkbox checked={checked} onChange={handleChange} name="doNotShow" color="primary"/> }
              label={<span style={{ fontSize: '0.75rem' }}>Do not show this message again</span>}
          />
        }

      </DialogContent>
      <DialogActions>
        <OkDialogButton onClick={handleClose}/>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state) => {
  return {
      open: state.pwaReducer.install_ios_open,
      isBlockPWA: state.pwaReducer.isBlockPWA,
      perm_blocked: state.pwaReducer.perm_blocked,
      ios_dialog_clicked: state.pwaReducer.ios_dialog_clicked
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      blockPWA: () => dispatch(blockPWA()),
      hideIOSPWADialog: () => dispatch(hideIOSPWADialog())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallIOSDialog)