import { isIOS } from "react-device-detect";

export function isLandscape(){
    if(window.innerHeight >= window.innerWidth){
        return false
    }else{
        return true
    }
}

export function getHeaderHeight(){
    if(isIOS && navigator.standalone){
        var landscape = isLandscape()
        if(landscape){
            return "55px"
        }
        else{
            return "100px"
        }
    }
    return "55px" 
}

export function getHeaderTopPadding(){
    if(isIOS && navigator.standalone){
        var landscape = isLandscape()
        if(landscape){
            return "0px"
        }
        else{
            return  "45px"
        }
    }
    return "0px"
}

export function getFooterBottomPadding(){
    if(isIOS && navigator.standalone){
        var landscape = isLandscape()
        if(landscape){
            return "0px"
        }
        else{
            return  "4em"
        }
    }
    return "1em !important"
}

export function getFooterTopPadding(){
    if(isIOS && navigator.standalone){
        var landscape = isLandscape()
        if(landscape){
            return "0em !important"
        }
        else{
            return  "1em !important"
        }
    }
    return "1em !important"
}

export function getFullScreenDialogPadding(){
    if(isIOS && navigator.standalone){
        var landscape = isLandscape()
        if(landscape){
            return "20px"
        }
        else{
            return  "40px"
        }
    }
    return "20px"
}

export function getFooterHeight(){
    if(isIOS && navigator.standalone){
        var landscape = isLandscape()
        if(landscape){
            return "70px"
        }
        else{
            return "90px"
        }
    }
    return "70px" 
}