// This is the standard information returned by the web server.
// It's used by carts in the old USMobile app.
// The old app used custom code to handle other aspects, but we should make more use of this in the new version.

class InventoryItem {
    constructor(copyFrom, companyNameText) {
        this.barcode = ''
        this.codeDescription = ''
        this.color = ''
        this.company = ''
        // this.setDescription('') // set 
        this.description = ''
        this.itemDescription = ''
        this.itemTitle = ''
        this.itemType = 0  // Product > 0 otherwise has Style
        this.manufacturer = ''
        this.priceFormatted = ''
        this.quantityFormatted = ''
        this.size = ''
        this.style = ''
        this.sizePromptText = ''
        this.SIZESVSTOCKBUTTONTEXT = 'Get VStock for All Sizes'
        this.thisColor = ''
        this.showVStockColumn = false
        this.getOtherStoreStockButtonVisible = true
        this.cancelVStockSizeButtonVisible = false
        this.nVStockItemsToGet = 0;
        this.showInactive = true;
    
        if (arguments.length === 0) {
            this.compute()
        } else {
            if (copyFrom instanceof InventoryItem) {
                this.copyFromItem(copyFrom)
            } else if (copyFrom.BARCODE) {
                this.copyFromProduct(copyFrom)
            } else {
                this.compute()
            }
        }
    }

    // This is a utility function, making no use of this.whatever
    calcDescription(product_DESCRIPTION, product_ITEM_DES) {
        if (typeof product_DESCRIPTION !== "undefined" && product_DESCRIPTION.length > 0) {
            return product_DESCRIPTION
        } else if (typeof product_ITEM_DES !== "undefined" && product_ITEM_DES.length > 0) {
            return product_ITEM_DES
        } else {
            return ''
        }
    }

    calcItemTitle(itemType, style, size, itemDescription, company) {
        // var isStyle = this.isStyle(itemType);
    
        return (this.isStyle ? style : size)
            + (itemDescription === '' ? '' : ' ' + itemDescription)
            + (company === '' ? '' : ' by ' + company);
    }

    setDescription(product_DESCRIPTION, product_ITEM_DES) {
        this.description = this.calcDescription(product_DESCRIPTION, product_ITEM_DES)
    }

    compute() {
        this.itemTitle = this.calcTitle(this.itemType, this.style, this.size, this.itemDescription, this.company)
        this.sizePromptText = this.calcSizePromptText(this.itemType)
        this.colorHtmlPhrase = this.calcColorHtmlPhrase()
        this.sizeHtmlPhrase = this.calcSizeHtmlPhrase(this.itemType, this.size)
    }

    copyFromItem(itemToCopy) {
        this.barcode = itemToCopy.barcode
        this.codeDescription = itemToCopy.codeDescription
        this.color = itemToCopy.color
        this.company = itemToCopy.company
        this.description = itemToCopy.description
        this.itemDescription = itemToCopy.itemDescription
        //this.itemTitle =   // Done in compute method
        this.itemType = itemToCopy.itemType
        this.manufacturer = itemToCopy.manufacturer
        this.priceFormatted = itemToCopy.priceFormatted
        this.quantityFormatted = itemToCopy.quantityFormatted
        this.size = itemToCopy.size
        //this.sizePromptText =   // Done in compute method
        this.style = itemToCopy.style

        this.compute()
    }

    copyFromProduct(product) {
        this.barcode = product.BARCODE
        this.codeDescription = product.CODEDESCRIPTION
        this.color = product.COLORDESC
        this.company = product.COMPANY
        this.setDescription(product.DESCRIPTION, product.ITEM_DES)
        this.itemDescription = product.ITEM_DES
        //this.itemTitle =   // Done in compute method
        this.itemType = product.ITEMTYPE
        this.manufacturer = product.MANUFACTURER
        this.priceFormatted = product.PRICEFORMATTED
        this.quantityFormatted = product.QTY_STOCKFORMATTED
        this.size = product.ITEM_SIZE
        //this.sizePromptText =   // Done in compute method
        this.style = product.STYLE

        // new code added
        this.itemImageSrc = product.ITEMIMAGESRC
        this.largeItemImageSrc = product.LARGEITEMIMAGESRC

        this.compute()
    }

    calcColorHtmlPhrase(itemType, color) {
        if (itemType === undefined) {
            itemType = this.itemType
        }
        if (color === undefined) {
            color = this.color
        }
        return (this.isStyle(itemType) && color.length > 0) ? 'Color ' + color + ';' : '' // space after Color should be &nbsp; but it doesn't work
    }
    
    // This method can be used as a utility by passing the optional parameter. Otherwise, it uses this.whatever
    calcSizeHtmlPhrase(itemType, size) {
        if (itemType === undefined) {
            itemType = this.itemType
        }
        if (size === undefined) {
            size = this.size
        }
        return (this.isStyle(itemType) && size.length > 0) ? 'Size ' + size + ';' : '' // space after Size should be &nbsp; but it doesn't work
    }
    
    
    
    // This is a utility function, making no use of this.whatever
    calcNameWithInactive(name, inactive) {
        return (inactive === true || inactive === 1 ? '*' : '') + name
    }
    
    // This method can be used as a utility by passing the optional parameter. Otherwise, it uses this.whatever
    calcSizePromptText(itemType) {
        if (itemType === undefined) {
            itemType = this.itemType
        }
        return this.isStyle(itemType) ? 'Size' : 'Product ID'
    }

    // This method can be used as a utility by passing the optional parameter. Otherwise, it uses this.whatever
    calcTitle(itemType, style, size, itemDescription, company) {
        if (itemType === undefined) {
            itemType = this.itemType
        }
        if (style === undefined) {
            style = this.style
        }
        if (size === undefined) {
            size = this.size
        }
        if (itemDescription === undefined) {
            itemDescription = this.itemDescription
        }
        if (company === undefined) {
            company = this.company
        }

        //TO DO FIX ERROR
        return (this.isStyle(itemType) ? style : size)
        + (itemDescription === '' ? '' : ' ' + itemDescription)
        + (company === '' ? '' : ' by ' + company)

    }
    
    // This method can be used as a utility by passing the optional parameter. Otherwise, it uses this.whatever
    isStyle(itemType) {
        if (itemType === undefined) {
            itemType = this.itemType
        }
        // The item is a Style, unless itemType > 0.
        return !(typeof itemType !== "undefined" && itemType > 0)
    }

    
    hideLookup() {
        this.showItemInfo();
        this.setLookupVisible = false;
    }

    showItemInfo() {
        this.showItemInfoVisible = true;
    }

    loadItem = (DATA, ACCOUNT, SESSION) => {
        
        var sizesLength = 0;
        
        this.description        = this.calcDescription(DATA.DESCRIPTION, DATA.ITEM_DES)
        this.color              = DATA.COLORDESC
        this.colors             = DATA.COLORS
        this.style              = DATA.STYLE
        this.size               = DATA.ITEM_SIZE
        this.sizes              = DATA.SIZES
        this.sizePromptText     = this.calcSizePromptText(DATA.ITEMTYPE)
        this.company            = DATA.COMPANY
        this.price              = DATA.PRICEFORMATTED
        this.quantity           = DATA.QTY_STOCKFORMATTED
        this.vstock             = ""
        // item.isStyle         = InventoryItem_isStyle(DATA.ITEMTYPE)
        this.sizeTableVisible   = Object.keys(DATA.SIZES).length > 1 ? true : false
        this.imgSrcThumb        = ACCOUNT.hostName + DATA.ITEMIMAGESRC.replace("_md", "_tm")
        this.imgsrc             = ACCOUNT.hostName + DATA.ITEMIMAGESRC
        this.imgsrcLarge        = ACCOUNT.hostName + DATA.LARGEITEMIMAGESRC
        this.manufacturer       = DATA.MANUFACTURER
        this.hasVstock          = DATA.HASVSTOCK > 0 ? true : false
        this.codeDescription    = DATA.CODEDESCRIPTION
        this.itemTitle          = this.calcItemTitle(DATA.ITEMTYPE, DATA.STYLE, DATA.ITEM_SIZE, DATA.ITEM_DES, DATA.COMPANY);
        this.thisColor          = DATA.COLORCODE
    
        //     if (PROGRAM_MODE === "RDMobile") {
        //         viewModel.hasVstock(false); //RD doesn't support vstock
        //     }
    
        var colorsLength = DATA.COLORS.length;
    
        if (DATA.ITEMIMAGESRC.toLowerCase().indexOf("noimage") >= 0) {
            this.imageVisible = false;
        } else {
            // viewModel.colorLookupOpened(); //ensure image isn't faded
            // $("#itemImage").off("click.dds").on("click.dds", function () {
            //     if (colorsLength > 1) {
            //         $("#itemImage").fadeTo("fast", 0.5, function () {
            //             $("#colorLookup").dxLookup("instance").open();
    
            //         });
            //     }
            // });
    
            this.imageVisible = true;
        }
    
        //     var dataGrid = $("#sizesGrid").dxDataGrid('instance');
        //     dataGrid.columnOption('VStock', 'visible', viewModel.hasVstock());
    
        this.inactiveItemsShown = false;
    
        for (var colorIndex = 0; colorIndex < colorsLength; colorIndex++) {
            this.colors[colorIndex].NAME = this.calcNameWithInactive(
                this.colors[colorIndex].NAME,
                this.colors[colorIndex].INACTIVE) +
                '  (' + this.colors[colorIndex].COLORCODE.trim() + ')';
        }
    
        if (this.isStyle()) {
            if (colorsLength > 1) {
                this.colorTextVisible = false;
                this.colorLookupVisible = true;
            } else {
                this.colorTextVisible = true;
                this.colorLookupVisible = false;
            }
        }
    
        sizesLength = DATA.SIZES.length;
        if (this.showInactive) {
            for (var sizeIndex = 0; sizeIndex < sizesLength; sizeIndex++) {
                if (this.sizes[sizeIndex].INACTIVE === 1) {
                    
                    this.inactiveItemsShown = true;
    
                    var sizeWithInactive = this.calcNameWithInactive(this.sizes[sizeIndex].SIZE, true);
    
                    if (this.size === this.sizes[sizeIndex].SIZE) {
                        this.size=sizeWithInactive;
                    }
                    this.sizes[sizeIndex].SIZE = sizeWithInactive;
                }
            }
        }
    
        var SIZESVSTOCKBUTTONTEXT = 'Get VStock for All Sizes'
        if (this.hasVstock && sizesLength > 0) {
            this.vstockQtyVisible = false;
            this.getVstockButtonVisible = true;
            if (sizesLength > 1) {
                this.getSizeVstockButtonText = SIZESVSTOCKBUTTONTEXT;
                this.getSizeVstockButtonVisible = true;
            } else {
                this.getSizeVstockButtonVisible = false;
            }
        } else {
            this.getVstockButtonVisible = false;
            this.getSizeVstockButtonVisible = false;
        }

        this.hideLookup();
        //     viewModel.loadingVisible(false);
    
        //     if (firstLoad) {
        //         firstLoad = false;
        //         if (openedFromSearch && colorsLength > 1) {
        //             $("#colorLookup").dxLookup('instance').open();
        //         }
        //         if (setThisColor) { //set after adjusting color NAME
        //             viewModel.thisColor(data.COLORCODE);
        //         }
        //         viewModel.setPromptWidths();
        //         if (DevExpress.devices.current().platform !== "ios") {
        //             $('#VStockButton').width($('#OtherStoreStockButton').width());
        //         }
        //     }
    }
}

export default InventoryItem